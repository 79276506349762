import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {required, emailInvalid} from "../../components/molecules/Form/validations";
import {
    fetchOrganizationInfo,
    toggleOrgInfoModal,
    clearInfoStateData,
    updateOrganizationInfoModal
} from "../../redux/actions/organizationActions";
import {cloneDeep} from "lodash";
import moment from "moment";
import Datatable from "../../components/molecules/Datatable/Datatable";
import ModalHeader from "../../components/molecules/Modal/ModalHeader";
import ModalBody from "../../components/molecules/Modal/ModalBody";
import Modal from "../../components/molecules/Modal/Modal";
import Input from "../../components/molecules/Form/Input";
import Checkbox from "../../components/molecules/Form/Checkbox";
import Grid from "../../components/grid/Grid";
import Col from "../../components/grid/Col";
import ModalFooter from "../../components/molecules/Modal/ModalFooter";

const pageSizes = [5, 50];
const defaultSort = {
    order: "DESC",
    orderBy: "parsed_privilege"
};

/**
 * Component that display and handles all the organizations information modal
 */
export class OrganizationInfo extends React.Component {
    state = {
        currentRowsPerPage: pageSizes[0],
        currentPage: 1,
        needFetch: false,
        filters: {
            sort: {
                order: defaultSort.order,
                orderBy: defaultSort.orderBy
            }
        },
        emailFetched: false,
        canEdit: false
    };

    async componentDidUpdate(prevProps, prevState) {
        const {orgInfo, userData} = this.props;

        //fetch organization information data when a organization is selected
        if (prevProps.orgInfo.orgInfoSelected !== orgInfo.orgInfoSelected && orgInfo.orgInfoSelected) {
            const {currentPage, currentRowsPerPage} = this.state;
            const pagination = {currentPage, currentRowsPerPage};
            await this.props.fetchOrganizationInfo(pagination, this.props.orgInfo.orgInfoSelected.id);
        }

        if (prevState.needFetch !== this.state.needFetch) {
            const {currentPage, currentRowsPerPage, filters} = this.state;
            const pagination = {currentPage: currentPage, currentRowsPerPage};
            await this.props.fetchOrganizationInfo(pagination, this.props.orgInfo.orgInfoSelected.id, filters);
        }

        if (prevProps.orgInfo.done !== orgInfo.done && orgInfo.done) {
            if (!this.state.emailFetched) {
                let canEdit, orgId = parseInt(localStorage.getItem('organizationId'));
                if (userData.user.roles.length === 0) {
                    canEdit = userData.user.hasAdminPrivilege;
                } else {
                    canEdit = userData.user.roles.some(userRole =>
                        (userRole === process.env.REACT_APP_ORG_WORKFLOW_OPERATOR && orgInfo.orgInfoSelected.id === orgId) ||
                        (userRole === process.env.REACT_APP_APP_APPLICATION_ADMIN)
                    );
                }
                this.setState({emailFetched: true, canEdit});
                this.props.change("redirected_email", this.props.orgInfo.data.redirectedEmail);
            }
        }
    }

    /**
     * clears the redux data of organization info
     */
    async componentWillUnmount() {
        await this.props.clearInfoStateData();
    }

    /**
     * function to update the table content according to page change
     * @param {number} page the page number that need to show
     */
    handlePageChange = page => {
        const {orgInfo} = this.props;
        const {currentRowsPerPage, filters} = this.state;
        const pagination = {currentPage: page, currentRowsPerPage};

        this.setState({currentPage: page}, () => this.props.fetchOrganizationInfo(pagination, orgInfo.orgInfoSelected.id, filters));
    };

    /**
     * function that handle when the info modal is closed
     */
    onCloseModal = async () => {
        this.props.reset();
        this.setState({currentPage: 1, currentRowsPerPage: pageSizes[0], filters: {sort: defaultSort}, emailFetched: false, canEdit: false});
        await this.props.clearInfoStateData();
    };

    /**
     * handle the table sorting function
     */
    requestSort = async sortConfig => {
        this.setState(prevState => ({
            currentPage: 1,
            filters: {
                sort: {
                    order: sortConfig.direction,
                    orderBy: sortConfig.key === 'parsed_name' ? 'name' : sortConfig.key
                }
            },
            needFetch: !prevState.needFetch
        }));
    };

    onSubmit = async values => {
        const {orgInfo} = this.props;
        let resp = await this.props.updateOrganizationInfoModal({...values, id: orgInfo.orgInfoSelected.id});
        if (resp === 200) {
            await this.onCloseModal();
        }
    };

    render() {
        const {
            t,
            orgInfo,
            systemParam,
            formIsRedirectedEmail,
            formRedirectedEmail,
            invalid: isFormInvalid,
            submitting
        } = this.props;
        const {currentPage, currentRowsPerPage, filters, emailFetched, canEdit} = this.state;

        const titles = [
            {
                name: t('modalTableInfo->firstColumn'),
                key: "parsed_privilege",
                sortable: true
            },
            {
                name: t('modalTableInfo->secondColumn'),
                key: "parsed_name",
                sortable: true
            },
            {
                name: t('modalTableInfo->thirdColumn'),
                key: "email_address",
                sortable: true
            },
            {
                name: t('modalTableInfo->fourthColumn'),
                key: "phone_no",
                sortable: true
            },
            {
                name: t('modalTableInfo->fifthColumn'),
                key: "last_updated",
                sortable: true
            }
        ];

        let orgDataCopy = [];

        orgDataCopy = cloneDeep(Object.values(orgInfo.data.roles));
        const totalItems = parseInt(orgInfo.data.totalItems);

        orgDataCopy.map(org => {
            systemParam.some(param => {
                if (param.key.split(".")[1] === org.privilege) {
                    org.parsed_privilege = param.value;
                    return true;
                }
                return false;
            })
            org.parsed_name = org.name + ' ' + org.last_name;
            org.last_updated = moment(org.last_updated).format('DD/MM/YYYY HH:mm');
            !org.phone_no && (org.phone_no = t('modalTableInfo->labelNotAvailable'));

            return org;
        });

        return (
            <Modal id="modal-organization-info" toggle={orgInfo.toggleModal} size="large">
                <ModalHeader onModalClose={this.onCloseModal}>
                    {orgInfo.data.info.name}
                    <p className="vl-title vl-title--h6 vl-u-spacer--none">
                        {t('modalTableInfo->title')} {orgInfo.data.info.organization_code}
                    </p>
                </ModalHeader>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="vl-form">
                    <ModalBody>
                        <Datatable
                            isLoading={!orgInfo.done}
                            titles={titles}
                            rows={orgDataCopy}
                            totalItems={totalItems}
                            hasPagination
                            currentPage={currentPage}
                            rowsPerPage={currentRowsPerPage}
                            pageSizes={pageSizes}
                            pageChange={this.handlePageChange}
                            defaultSort={{key: filters.sort.orderBy, direction: filters.sort.order}}
                            requestSort={this.requestSort}
                        />
                        {
                            emailFetched &&
                            <Grid className="vl-u-spacer--small">
                                <Col lg="6">
                                    <Field
                                        component={Checkbox}
                                        name="is_redirected_email"
                                        label={t('modalTableInfo->checkboxRedirectedEmail')}
                                        defaultChecked={orgInfo.data?.isRedirectedEmail}
                                        isDisabled={!canEdit}
                                    />
                                    <Field
                                        component={Input}
                                        name="redirected_email"
                                        label={t('modalTableInfo->inputRedirectedEmail')}
                                        placeholder={t('modalTableInfo->inputRedirectedEmailPlaceholder')}
                                        isBlock
                                        validate={formIsRedirectedEmail ? [required, emailInvalid] : []}
                                        isDisabled={!canEdit || !formIsRedirectedEmail}
                                    />
                                </Col>
                            </Grid>
                        }
                    </ModalBody>
                    {
                        canEdit &&
                        <ModalFooter
                            btnLabel={t("modalTableInfo->btnUpdateLabel")}
                            btnActionType="submit"
                            btnActionDisabled={isFormInvalid || submitting || (orgInfo.data.isRedirectedEmail === formIsRedirectedEmail && orgInfo.data.redirectedEmail === formRedirectedEmail)}
                            btnActionIsLoading={submitting}
                            onModalClose={this.onCloseModal}
                        />
                    }
                </form>
            </Modal>
        );
    }
}

const formSelector = formValueSelector("orgInfoForm");

const mapStateToProps = state => {
    return {
        userRoles: state.theme.userData.user.roles,
        orgInfo: state.org.orgInfo,
        systemParam: state.param.systemParam.data,
        formIsRedirectedEmail: formSelector(state, 'is_redirected_email'),
        formRedirectedEmail: formSelector(state, 'redirected_email') || "",
        initialValues: {is_redirected_email: false},
        userData: state.theme.userData,
    };
};

const translationWrapped = withTranslation('organization')(OrganizationInfo);

const reduxFormWrapped = reduxForm({
    form: "orgInfoForm"
})(translationWrapped);

export default connect(mapStateToProps, {
    fetchOrganizationInfo,
    toggleOrgInfoModal,
    clearInfoStateData,
    updateOrganizationInfoModal
})(reduxFormWrapped);