import React, {Suspense, lazy} from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {getUserInfo, setAuth} from "../../../redux/actions/themeActions";
import {selectSpecialOrg, toggleOrgInfoModal} from "../../../redux/actions/organizationActions";
import {fetchParameters} from "../../../redux/actions/parameterActions";
import PrivateRoute from "./PrivateRoute";
import FunctionalHeader from "../../../components/organisms/FunctionalHeader/FunctionalHeader";
import Loader from "../../../components/atoms/Loader";
import moment from "moment";
import {isEmpty} from "lodash";

const CallbackComponent = lazy(() => import("../LoginCallbackComponent"));
const OrganizationRedirect = lazy(() => import("../../Organization/OrganizationRedirect"));
const Organization = lazy(() => import("../../Organization"));
const Domain = lazy(() => import("../../Domain"));
const Certificate = lazy(() => import("../../Certificate"));
const Applications = lazy(() => import("../../Application"));
const CertificateTemplate = lazy(() => import("../../Configuration/CertificateTemplate/CertificateTemplate"));
const ValidationRule = lazy(() => import("../../Configuration/ValidationRule/ValidationRule"));
const EmailSetting = lazy(() => import("../../Configuration/EmailSetting/EmailSetting"));
const Administration = lazy(() => import("../../Administrator"));
const Parameter = lazy(() => import("../../Configuration/Parameter/Parameter"));
const WhitelistOrigin = lazy(() => import("../../Configuration/WhitelistOrigin/WhitelistOrigin"));

const Webcomponents = lazy(() => import("../../Webcomponents/Webcomponents"));
const NotFound = lazy(() => import("../../Error/404"));
const Forbidden = lazy(() => import("../../Error/403"));

class Routes extends React.Component {
    /**
     * check if there's a logged info and token not expired
     */
    async componentDidMount() {
        const oidc = JSON.parse(sessionStorage.getItem("oidcInfo"));
        if (oidc) {
            const now = moment(), expires_at = moment.unix(oidc.expires_at);
            if (expires_at.diff(now) > 0) {
                await this.props.setAuth();
            }
        }
    }

    /**
     * check if authentication is done and then fetch user info from BE
     */
    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.authDone !== this.props.authDone) {
            await this.props.getUserInfo();
            await this.props.fetchParameters({}, true);
        }

        if (prevProps.showUserMenu !== this.props.showUserMenu && !this.props.showUserMenu) {
            if (!this.props.selectedSpecialOrg.selected) {
                await this.props.getUserInfo();
            }
        }
    }

    render() {
        const {userData, showTabs, showUserMenu, selectSpecialOrg, systemParam, toggleOrgInfoModal} = this.props;

        return (
            userData.done && systemParam.done ?
                <>
                    <FunctionalHeader
                        hasTabs={showTabs}
                        hasSelects={showUserMenu}
                        userData={userData}
                        selectSpecialOrg={location => selectSpecialOrg(location)}
                        systemParam={systemParam}
                        toggleOrgInfoModal={toggleOrgInfoModal}
                    />
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            <Route exact path="/">
                                {
                                    !isEmpty(userData.user.roles) ? userData.user.roles.some(userRole => (userRole === process.env.REACT_APP_ORG_CERTIFICATE_ADMIN_ORG) ||
                                        (userRole === process.env.REACT_APP_ORG_CERTIFICATE_ADMIN_APP) ||
                                        (userRole === process.env.REACT_APP_APP_APPLICATION_ADMIN) ||
                                        (userRole === process.env.REACT_APP_APP_CERTIFICATE_ADMIN) ||
                                        (userRole === process.env.REACT_APP_APP_CONFIG_ADMIN) ||
                                        (userRole === process.env.REACT_APP_APP_HELPDESK)
                                        ) ?
                                        <Redirect to="/certificaten"/>
                                        :
                                        <Redirect to="/toepassingen"/>
                                        :
                                        <Redirect to="/organisatie"/>
                                }
                            </Route>
                            <Route exact path="/oidc/callback" component={CallbackComponent}/>
                            <Route exact path="/organisatie/redirect" component={OrganizationRedirect}/>
                            <PrivateRoute
                                exact
                                path="/organisatie"
                                isOrgPage
                                component={Organization}
                                userRoles={userData.user.roles}
                            />
                            <PrivateRoute path="/domeinen" exact component={Domain} userRoles={userData.user.roles}/>
                            <PrivateRoute
                                path="/certificaten"
                                exact
                                component={Certificate}
                                routeRoles={[
                                    process.env.REACT_APP_ORG_CERTIFICATE_ADMIN_ORG,
                                    process.env.REACT_APP_ORG_CERTIFICATE_ADMIN_APP,
                                    process.env.REACT_APP_APP_APPLICATION_ADMIN,
                                    process.env.REACT_APP_APP_CERTIFICATE_ADMIN,
                                    process.env.REACT_APP_APP_CONFIG_ADMIN,
                                    process.env.REACT_APP_APP_HELPDESK
                                ]}
                                userRoles={userData.user.roles}
                            />
                            <PrivateRoute path="/toepassingen" exact component={Applications}
                                          userRoles={userData.user.roles}/>
                            <PrivateRoute
                                path="/configuratie/certificaat_templates"
                                exact
                                component={CertificateTemplate}
                                routeRoles={[
                                    process.env.REACT_APP_APP_APPLICATION_ADMIN,
                                    process.env.REACT_APP_APP_CERTIFICATE_ADMIN,
                                    process.env.REACT_APP_APP_CONFIG_ADMIN,
                                    process.env.REACT_APP_APP_HELPDESK
                                ]}
                                userRoles={userData.user.roles}
                            />
                            <PrivateRoute
                                path="/configuratie/validatieregels"
                                exact
                                component={ValidationRule}
                                routeRoles={[
                                    process.env.REACT_APP_APP_APPLICATION_ADMIN,
                                    process.env.REACT_APP_APP_CERTIFICATE_ADMIN,
                                    process.env.REACT_APP_APP_CONFIG_ADMIN,
                                    process.env.REACT_APP_APP_HELPDESK
                                ]}
                                userRoles={userData.user.roles}
                            />
                            <PrivateRoute
                                path="/configuratie/email"
                                exact
                                component={EmailSetting}
                                routeRoles={[
                                    process.env.REACT_APP_APP_APPLICATION_ADMIN,
                                    process.env.REACT_APP_APP_CERTIFICATE_ADMIN,
                                    process.env.REACT_APP_APP_CONFIG_ADMIN,
                                    process.env.REACT_APP_APP_HELPDESK
                                ]}
                                userRoles={userData.user.roles}
                            />
                            <PrivateRoute
                                path="/configuratie/parameter"
                                exact
                                component={Parameter}
                                routeRoles={[
                                    process.env.REACT_APP_APP_APPLICATION_ADMIN,
                                    process.env.REACT_APP_APP_CERTIFICATE_ADMIN,
                                    process.env.REACT_APP_APP_CONFIG_ADMIN,
                                    process.env.REACT_APP_APP_HELPDESK
                                ]}
                                userRoles={userData.user.roles}
                            />
                            <PrivateRoute
                                path="/configuratie/whitelist-origin"
                                exact
                                component={WhitelistOrigin}
                                routeRoles={[
                                    process.env.REACT_APP_APP_APPLICATION_ADMIN,
                                    process.env.REACT_APP_APP_CERTIFICATE_ADMIN,
                                    process.env.REACT_APP_APP_CONFIG_ADMIN,
                                    process.env.REACT_APP_APP_HELPDESK
                                ]}
                                userRoles={userData.user.roles}
                            />
                            <PrivateRoute
                                path="/configuratie/admin"
                                exact
                                component={Administration}
                                userRoles={userData.user.roles}
                            />
                            <Route path="/webcomponents" exact component={Webcomponents}/>
                            <Route path="/forbidden" exact component={Forbidden}/>
                            <Route component={NotFound}/>
                        </Switch>
                    </Suspense>
                </>
                :
                <Loader/>
        );
    }
}

const mapStateToProps = state => {
    return {
        authDone: state.theme.authDone,
        userData: state.theme.userData,
        showTabs: state.theme.showTabs,
        showUserMenu: state.theme.showUserMenu,
        systemParam: state.param.systemParam,
        selectedSpecialOrg: state.org.org.selectSpecialOrg,
    };
};

export default connect(mapStateToProps, {getUserInfo, setAuth, selectSpecialOrg, fetchParameters, toggleOrgInfoModal})(Routes);
