import React from "react";
import PropTypes from "prop-types";

/**
 * Component that will be used to show the notifications located on the header.
 */
const NotificationRow = props => {
    const {isUnread, onClick, children} = props;

    return (
        <li
            className="vl-popover__notification vl-popover__link-list__item"
            onClick={onClick}
        >
            <div className={`vl-popover__notification-item${isUnread ? ' vl-popover__notification--unread' : ' vl-popover__notification--read'}`}>
                {children}
            </div>
        </li>
    );
}

NotificationRow.propTypes = {
    /**
     * [Optional] boolean that verifies if the notification is new.
     */
    isUnread: PropTypes.bool,
    /**
     * [Optional] function callback when the user clicks the row.
     */
    onClick: PropTypes.func,
    /**
     * [Required] display the notification message.
     */
    children: PropTypes.node.isRequired,
};

NotificationRow.defaultProps = {
    isNewNotification: false,
    children: "Notification row"
};

export default NotificationRow;
