import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

/**
 * Adds a header element with the modal. Use it together with Modal component.
 */
const ModalHeader = ({onModalClose, children}) => {
    const {t} = useTranslation('common');
    return (
        <>
            <button type="button" className="vl-modal-dialog__close" onClick={onModalClose}>
                <i className="vl-modal-dialog__close__icon vl-vi vl-vi-cross" aria-hidden="true"/>
                <span className="vl-u-visually-hidden">{t('modal->btnCloseAccessibility')}</span>
            </button>
            <h2 className="vl-modal-dialog__title" id="modal-toggle-1-title">{children}</h2>
        </>
    );
};

ModalHeader.propTypes = {
    /**
     * [Required] function callback when user click on cancel button.
     */
    onModalClose: PropTypes.func.isRequired,
    /**
     * [Required] content to be displayed with modal header.
     */
    children: PropTypes.node.isRequired
};

ModalHeader.defaultProps = {
    onModalClose: () => null,
    children: "Modal header"
};

export default ModalHeader;