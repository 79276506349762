import {
    ORG_FETCH_DATA,
    ORG_DONE,
    ORG_ERROR,
    ORG_ERROR_CLEAR,
    ORG_PENDING,
    ORG_CLEAR_STATE_DATA,
    ORG_INFO_TOGGLE_MODAL,
    ORG_INFO_PENDING,
    ORG_INFO_DONE,
    ORG_INFO_FETCH_DATA,
    ORG_INFO_ERROR,
    ORG_INFO_CLEAR_STATE_DATA,
    ORG_SELECT_SPECIAL_ORG
} from "../actions/organizationActions";
import {sortBy} from "lodash";

const initialState = {
    org: {
        done: false,
        data: {
            organizations: [],
            specialOrg: {},
            totalItems: 0
        },
        error: null,
        selectSpecialOrg: {
            selected: false,
            location: null
        }
    },
    orgInfo: {
        done: false,
        data: {
            info: {
                name: null,
                code: null
            },
            roles: [],
            totalItems: 0,
            isRedirectedEmail: false,
            redirectedEmail: null,
        },
        error: null,
        orgInfoSelected: null,
        toggleModal: false
    },
};

const orgReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORG_FETCH_DATA:
            return {
                ...state,
                org: {...state.org, data: {organizations: sortBy(action.payload.organizations, "name")}}
            };
        case ORG_DONE:
            return {...state, org: {...state.org, done: true, error: null}};
        case ORG_PENDING:
            return {...state, org: {...state.org, done: false, error: null}};
        case ORG_ERROR:
            return {...state, org: {...state.org, done: true, error: action.payload}};
        case ORG_ERROR_CLEAR:
            return {...state, org: {...state.org, error: null}};
        case ORG_INFO_TOGGLE_MODAL:
            return {...state,
                orgInfo: {
                    ...state.orgInfo,
                    toggleModal: action.payload.toggleModal,
                    orgInfoSelected: action.payload.organization
                }
            };
        case ORG_INFO_FETCH_DATA:
            return {
                ...state,
                orgInfo: {...state.orgInfo,
                    data: {
                        info: {
                           name: action.payload.info.name,
                           organization_code: action.payload.info.organization_code
                        },
                        roles: action.payload.response,
                        totalItems: action.payload.totalRows,
                        isRedirectedEmail: action.payload.info.is_redirected_email,
                        redirectedEmail: action.payload.info.redirected_email
                    }
                }
            };
        case ORG_INFO_DONE:
            return {...state, orgInfo: {...state.orgInfo, done: true, error: null}};
        case ORG_INFO_PENDING:
            return {...state, orgInfo: {...state.orgInfo, done: false, error: null}};
        case ORG_INFO_ERROR:
            return {...state, orgInfo: {...state.orgInfo, done: true, error: action.payload}};
        case ORG_INFO_CLEAR_STATE_DATA:
            return {...state, orgInfo: initialState.orgInfo};
        case ORG_SELECT_SPECIAL_ORG:
            return {...state, org: {...state.org, selectSpecialOrg: {selected: true, location: action.payload}}};
        case ORG_CLEAR_STATE_DATA:
            return initialState;
        default:
            return state;
    }
};

export default orgReducer;