import axios from "axios";
import {cloneDeep, isEmpty} from "lodash";
import {handleErrors, getUserInformation, getOrgNames} from "./commonActions";

export const DOM_FETCH_DATA = 'DOM_FETCH_DATA';
export const DOM_PENDING = 'DOM_PENDING';
export const DOM_DONE = 'DOM_DONE';
export const DOM_ERROR = 'DOM_ERROR';
export const DOM_ERROR_CLEAR = 'DOM_ERROR_CLEAR';
export const DOM_BY_ID_FETCH_DATA = 'DOM_BY_ID_FETCH_DATA';
export const DOM_BY_ID_PENDING = 'DOM_BY_ID_PENDING';
export const DOM_BY_ID_DONE = 'DOM_BY_ID_DONE';
export const DOM_BY_ID_ERROR = 'DOM_BY_ID_ERROR';
export const DOM_BY_ID_CLEAR = 'DOM_BY_ID_CLEAR';
export const DOM_CLEAR_VALIDATION = 'DOM_CLEAR_VALIDATION';
export const DOM_NAME_ALREADY_EXISTS = 'DOM_NAME_ALREADY_EXISTS';
export const DOM_INVALID_DOMAIN_NAME = 'DOM_INVALID_DOMAIN_NAME';
export const DOM_NAME_VALIDATED = 'DOM_NAME_VALIDATED';
export const DOM_FETCH_LOGS = 'DOM_FETCH_LOGS';
export const DOM_FETCH_LOGS_DONE = 'DOM_FETCH_LOGS_DONE';
export const DOM_FETCH_LOGS_ERROR = 'DOM_FETCH_LOGS_ERROR';
export const DOM_FETCH_LOGS_PENDING = 'DOM_FETCH_LOGS_PENDING';
export const DOM_DELEGATED_FETCH_DATA = 'DOM_DELEGATED_FETCH_DATA';
export const DOM_DELEGATED_PENDING = 'DOM_DELEGATED_PENDING';
export const DOM_DELEGATED_DONE = 'DOM_DELEGATED_DONE';
export const DOM_DELEGATED_ERROR = 'DOM_DELEGATED_ERROR';
export const DOM_PENDING_FETCH_DATA = 'DOM_PENDING_FETCH_DATA';
export const DOM_PENDING_PENDING = 'DOM_PENDING_PENDING';
export const DOM_PENDING_DONE = 'DOM_PENDING_DONE';
export const DOM_PENDING_ERROR = 'DOM_PENDING_ERROR';
export const DOM_PENDING_ERROR_CLEAR = 'DOM_PENDING_ERROR_CLEAR';
export const DOM_CLEAR_STATE_DATA = 'DOM_CLEAR_STATE_DATA';
export const DOM_DOM_CLEAR_STATE_DATA = 'DOM_DOM_CLEAR_STATE_DATA';
export const DOM_DELEGATED_DOM_CLEAR_STATE_DATA = 'DOM_DELEGATED_DOM_CLEAR_STATE_DATA';

let apiURL = process.env.REACT_APP_API_URL, apiUrlES = process.env.REACT_APP_API_ES_URL;

//initial values for the pagination
const initialPagination = {
    currentPage: 1,
    currentRowsPerPage: 10
};

//initial values for filtering table results
const initialFilters = {
    searchString: "",
    domIds: [],
    status: ["active", "requested", "refused"],
    sort: {
        order: "DESC",
        orderBy: "requested_on"
    }
};

//initial values for filtering pending table results
const pendingInitialFilters = {
    status: ["requested"],
    sort: {
        order: "DESC",
        orderBy: "requested_on"
    }
};

/**
 * Fetches all the domain logs
 *
 * @param {number} domainId the domain id
 */
export const fetchDomainLogs = (domainId) => async dispatch => {
    dispatch({type: DOM_FETCH_LOGS_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/logs/domain', {
            organization_id: orgId,
            queryParameters: {
                domain_id: domainId,
                order: "DESC",
                orderBy: "log_timestamp",
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            dispatch({type: DOM_FETCH_LOGS, payload: isEmpty(response.data) ? {response: []} : response.data});
        }
        dispatch({type: DOM_FETCH_LOGS_DONE});
    } catch (err) {
        dispatch(handleErrors(err, DOM_FETCH_LOGS_ERROR));
    }
};

/**
 * fetches all domains
 *
 * @param {object} pagination number of domains shown per page
 * @param {object} filters receive an object with the filters configurations
 */
export const fetchDomains = (pagination = initialPagination, filters = initialFilters) => async dispatch => {
    dispatch({type: DOM_PENDING});
    try {
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);
        const domResponse = await axios.post(apiURL + '/domain/search', {
            organization_id: orgId,
            queryParameters: {
                organization_id: orgId,
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                order: filtersCopy.sort.order,
                orderBy: filtersCopy.sort.orderBy,
                status: filtersCopy.status,
                "dom.id": filtersCopy.searchString ? filtersCopy.domIds : [],
                withInfo: true
            },
            returnValues: ["dom.id as id", "dom.name as name", "organization_id", "status", "requested_on", "reason", "org.name as organization_name"]
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(domResponse?.data.response)) {
            const data = await dispatch(getOrgNames(domResponse.data, "organization_id"));
            dispatch({type: DOM_FETCH_DATA, payload: data});
        } else {
            dispatch({type: DOM_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: DOM_DONE});
    } catch (err) {
        dispatch(handleErrors(err, DOM_ERROR));
    }
};

/**
 * fetches all the filtered domains
 *
 * @param {object} pagination number of domains shown per page
 * @param {object} filters receive an object with the filters configurations
 * @param {boolean} allRequests flag if want all the pending requests (for admin page)
 */
export const filterFetchDomains = (pagination = initialPagination, filters = initialFilters, allRequests = false) => async dispatch => {
    dispatch({type: allRequests ? DOM_PENDING_PENDING : DOM_PENDING});
    try {
        let filtersCopy = filters, domIds = [];
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const domESResponse = await axios.post(apiUrlES + '/essearch/domain', {
            searchstring: filtersCopy.searchString,
            organization_id: allRequests ? [] : orgId,
            attributes: ["name", "organization_name", "requested_on"],
            status: filters.status,
            domain_id: []
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(domESResponse?.data)) {
            await Promise.all(domESResponse.data.map(dom => domIds.push(parseInt(dom._source.id))));
            filtersCopy.domIds = domIds;
            allRequests ? await dispatch(fetchPendingDomains(pagination, true, filtersCopy)) : await dispatch(fetchDomains(pagination, filtersCopy));
        } else {
            dispatch({type: allRequests ? DOM_PENDING_FETCH_DATA : DOM_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: allRequests ? DOM_PENDING_DONE: DOM_DONE});
    } catch (err) {
        dispatch(handleErrors(err, allRequests ? DOM_PENDING_ERROR : DOM_ERROR));
    }
};

/**
 * fetch the selected domain data
 *
 * @param {number} id the id of domain to fetch
 */
export const fetchDomainById = id => async dispatch => {
    dispatch({type: DOM_BY_ID_CLEAR});
    dispatch({type: DOM_BY_ID_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const query = {
            organization_id: orgId,
            queryParameters: {
                organization_id: orgId,
                id: [id]
            },
            returnValues: ["id", "name", "organization_id", "status", "requested_on", "reason"]
        }

        const domResponse = await axios.post(apiURL + '/domain/search', query, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(domResponse?.data.response)) {
            const data = await dispatch(getOrgNames(domResponse.data, "organization_id"));
            dispatch({type: DOM_BY_ID_FETCH_DATA, payload: data});
        } else {
            dispatch(fetchDomains());
        }
        dispatch({type: DOM_BY_ID_DONE});
    } catch (err) {
        dispatch(handleErrors(err, DOM_BY_ID_ERROR));
    }
};

/**
 * validates if the 'name' on the input already exists
 *
 * @param {string} name name of the domain being validated
 */
export const validateDomainName = name => async dispatch => {
    dispatch({type: DOM_CLEAR_VALIDATION});
    if (name) {
        try {
            const authorizationToken = sessionStorage.getItem('authorizationToken');
            const orgId = parseInt(localStorage.getItem('organizationId'));
            const response = await axios.post(apiURL + '/domain/validate', {
                organization_id: orgId,
                data: {
                    name: name,
                }
            }, {headers: {Authorization: authorizationToken}});

            if (response) {
                dispatch({type: DOM_NAME_VALIDATED});
            }
        } catch (err) {
            dispatch(handleErrors(err, DOM_ERROR));
        }
    }
};

/**
 * creates a new domain
 *
 * @param {object} values all the form information that will be used to create the new domain
 */
export const addNewDomain = values => async dispatch => {
    dispatch({type: DOM_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/domain/add', {
            organization_id: orgId,
            data: {
                name: values.name,
                organization_id: orgId,
                certificateTemplates: values.certificateTemplates,
                reason: values.domain_reason
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return 200;
        }
    } catch (err) {
        dispatch(handleErrors(err, DOM_ERROR));
    }
};

/**
 * updates the domain with new information
 *
 * @param {number} domId the id of the domain to update
 * @param {object} values values necessary to update the application, in this case only the description is necessary
 */
export const updateDomain = (domId, values) => async dispatch => {
    dispatch({type: DOM_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/domain/update', {
            organization_id: orgId,
            data: {
                id: domId,
                reason: values.reason,
                certificateTemplates: values.certificateTemplates
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, DOM_ERROR));
    }
};

/**
 * deletes the selected domain from the list
 *
 * @param {number} id id of the domain to be deleted.
 */
export const deleteDomain = id => async dispatch => {
    dispatch({type: DOM_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/domain/delete', {
            organization_id: orgId,
            data: {
                id: id
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, DOM_ERROR));
    }
};

/**
 * handle the domain transfer
 *
 * @param {object} values all the form information that will be used to transfer a domain
 */
export const transferDomain = values => async dispatch => {
    dispatch({type: DOM_PENDING_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/domain/transfer', {
            organization_id: orgId,
            data: {
                id: values.id,
                name: values.name,
                organization_id: values.organization_id
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, DOM_ERROR));
    }
};

/**
 * fetches all delegated domains
 *
 * @param {object} pagination number of domains shown per page
 * @param {object} filters receive an object with the filters configurations
 */
export const fetchDelegatedDomains = (pagination = initialPagination, filters = initialFilters) => async dispatch => {
    dispatch({type: DOM_DELEGATED_PENDING});
    try {
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);
        const domResponse = await axios.post(apiURL + '/domain/delegated', {
            organization_id: orgId,
            queryParameters: {
                ['dom.id']: filtersCopy.domIds || undefined,
                organization_id: orgId,
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                status: ["active"],
                order: filtersCopy.sort.order,
                orderBy: filtersCopy.sort.orderBy,
                withInfo: true
            },
            returnValues: ["dom.id as id", "dom.name as name", "organization_id", "requested_on", "org.name as organization_name"]
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(domResponse?.data.response)) {
            const data = await dispatch(getOrgNames(domResponse.data, "organization_id"));
            dispatch({type: DOM_DELEGATED_FETCH_DATA, payload: data});
        } else {
            dispatch({type: DOM_DELEGATED_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: DOM_DELEGATED_DONE});
    } catch (err) {
        dispatch(handleErrors(err, DOM_DELEGATED_ERROR));
    }
};

/**
 * fetch all the filtered delegated applications
 *
 * @param {object} pagination receive an object with the pagination configurations
 * @param {object} filters receive an object with the filters configurations
 */
export const filterFetchDelegatedDomains = (pagination = initialPagination, filters = initialFilters) => async dispatch => {
    dispatch({type: DOM_DELEGATED_PENDING});
    try {
        let filtersCopy = filters, domIds = [];
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));

        const domResponse = await axios.post(apiURL + '/domain/delegated', {
            organization_id: orgId,
            queryParameters: {
                status: ["active"],
                organization_id: orgId
            },
            returnValues: ["id"]
        }, {headers: {Authorization: authorizationToken}});

        await Promise.all(domResponse.data.response.map(dom => domIds.push(dom.id)));

        const domESResponse = await axios.post(apiUrlES + '/essearch/domain', {
            searchstring: filtersCopy.searchString,
            organization_id: [],
            attributes: ["name", "organization_name", "requested_on"],
            status: ["active"],
            domain_id: domIds
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(domESResponse?.data)) {
            domIds = [];
            await Promise.all(domESResponse.data.map(app => domIds.push(parseInt(app._source.id))));
            filtersCopy.domIds = domIds;
            await dispatch(fetchDelegatedDomains(pagination, filtersCopy));
        } else {
            dispatch({type: DOM_DELEGATED_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: DOM_DELEGATED_DONE});
    } catch (err) {
        dispatch(handleErrors(err, DOM_DELEGATED_ERROR));
    }
};

/**
 * fetches all delegated domains
 *
 * @param {object} pagination number of domains shown per page
 * @param {object} filters receive an object with the filters configurations
 * @param {boolean} allRequests flag if want all the pending requests (for admin page)
 */
export const fetchPendingDomains = (pagination = initialPagination, allRequests = false, filters = pendingInitialFilters) => async dispatch => {
    dispatch({type: DOM_PENDING_PENDING});
    try {
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);
        const domResponse = await axios.post(apiURL + '/domain/search', {
            organization_id: orgId,
            queryParameters: {
                id: allRequests ? filters.domIds : undefined,
                organization_id: allRequests ? undefined : orgId,
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                order: filtersCopy.sort.order,
                orderBy: filtersCopy.sort.orderBy,
                status: ["requested"],
                withInfo: true
            },
            returnValues: [
                "id",
                "name",
                "organization_id",
                "requestor_org",
                "requestor_acc",
                "requested_on",
                "reason",
                "requested_by"
            ]
        }, {headers: {Authorization: authorizationToken}});

        if (!isEmpty(domResponse?.data.response)) {
            let data = await dispatch(getOrgNames(domResponse.data, "organization_id"));
            await Promise.all(data.response.map(async dom => {
                const response = await axios.post(apiURL + '/certificate-template/search-per-domain', {
                    organization_id: orgId,
                    queryParameters: {
                        domain_name: dom.name
                    }
                }, {headers: {Authorization: authorizationToken}});
                if (!isEmpty(response?.data.response)) {
                    dom.cer_templates = response.data.response.map(cerTemp => cerTemp.certificate_name);
                }
                const userInfoResponse = await dispatch(getUserInformation(dom.requestor_org, dom.requestor_acc));
                dom.requestor_name = `${userInfoResponse.name} ${userInfoResponse.last_name}`;
                dom.requestor_email = userInfoResponse.email_address;

                return dom;
            }));
            dispatch({type: DOM_PENDING_FETCH_DATA, payload: data});
        } else {
            dispatch({type: DOM_PENDING_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: DOM_PENDING_DONE});
    } catch (err) {
        dispatch(handleErrors(err, DOM_PENDING_ERROR));
    }
};

/**
 * update the status of a pending domain
 *
 * @param {string} type status of the pending domain to be updated
 * @param {number} id the domain id to be updated
 * @param {string} refusalReason the reason why the domain was refused
 */
export const updatePendingDomain = (type, id, refusalReason) => async dispatch => {
    dispatch({type: DOM_PENDING_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + `/domain/${type}`, {
            organization_id: orgId,
            data: {
                id: id,
                refusal_reason: refusalReason
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, DOM_PENDING_ERROR));
    }
};

/**
 * clear the domById data
 */
export const clearDomByIdData = () => async dispatch => {
    dispatch({type: DOM_BY_ID_CLEAR});
};

/**
 * clears all the state data
 */
export const clearStateData = () => async dispatch => {
    dispatch({type: DOM_CLEAR_STATE_DATA});
};

/**
 * clears all the "my domains" state data
 */
export const clearDomStateData = () => async dispatch => {
    dispatch({type: DOM_DOM_CLEAR_STATE_DATA});
};

/**
 * clears all the "delegated domains" state data
 */
export const clearDelegatedDomStateData = () => async dispatch => {
    dispatch({type: DOM_DELEGATED_DOM_CLEAR_STATE_DATA});
};