import React from "react";
import PropTypes from "prop-types";

/**
 * Use an icon to add an extra visual element to a label.
 * The size of the icon is defined by the parent. If the icon is an only child, it won't append any margin left or right.
 * @see Webcomponent: <a href="https://overheid.vlaanderen.be/webuniversum/v3/documentation/atoms/vl-ui-icon" target="_blank">Icon</a>
 *
 * @example ../../styleguide/atoms/Icon.md
 */
const Icon = props => {
    const {name, size, iconPosition, isLighter, className, title} = props;
    let sizeClass = "", iconPositionClass = "";

    size === "small" && (sizeClass = " vl-icon--small");
    size === "large" && (sizeClass = " vl-icon--large");
    iconPosition === "after" && (iconPositionClass = " vl-icon--after");
    iconPosition === "before" && (iconPositionClass = " vl-icon--before");
    iconPosition === "after|before" && (iconPositionClass = " vl-icon--after vl-icon--before");

    return (
        <span title={title} className={`vl-icon vl-vi vl-vi-${name}${iconPositionClass}${sizeClass}${isLighter ? ' vl-icon--light' : ''}${className ? ` ${className}` : ''}`} aria-hidden="true"/>
    );
};

Icon.propTypes = {
    /**
     * [Required] name of the icon to display.
     */
    name: PropTypes.string.isRequired,
    /**
     * [Optional] size of the icon.
     */
    size: PropTypes.oneOf(["", "small", "large"]),
    /**
     * [Optional] use the after (or before) modifier to add proper spacing between the icon and the previous/following content.
     */
    iconPosition: PropTypes.oneOf(["after", "before", "after|before"]),
    /**
     * [Optional] use the light modifier to give the icon a light color.
     */
    isLighter: PropTypes.bool,
    /**
     * [Optional] add additional classes to icon.
     */
    className: PropTypes.string,
    /**
     * [Optional] add a title to icon.
     */
    title: PropTypes.string
};

Icon.defaultProps = {
    name: "question-mark-small",
    size: "",
    iconPosition: null,
    isLighter: false,
    className: null,
    title: null
};

export default Icon;