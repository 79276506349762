import {
    DOM_FETCH_DATA,
    DOM_DONE,
    DOM_ERROR,
    DOM_ERROR_CLEAR,
    DOM_PENDING,
    DOM_BY_ID_FETCH_DATA,
    DOM_BY_ID_PENDING,
    DOM_BY_ID_DONE,
    DOM_BY_ID_CLEAR,
    DOM_BY_ID_ERROR,
    DOM_NAME_ALREADY_EXISTS,
    DOM_INVALID_DOMAIN_NAME,
    DOM_NAME_VALIDATED,
    DOM_CLEAR_VALIDATION,
    DOM_FETCH_LOGS,
    DOM_FETCH_LOGS_DONE,
    DOM_FETCH_LOGS_PENDING,
    DOM_FETCH_LOGS_ERROR,
    DOM_DELEGATED_FETCH_DATA,
    DOM_DELEGATED_DONE,
    DOM_DELEGATED_PENDING,
    DOM_DELEGATED_ERROR,
    DOM_PENDING_FETCH_DATA,
    DOM_PENDING_DONE,
    DOM_PENDING_ERROR,
    DOM_PENDING_ERROR_CLEAR,
    DOM_PENDING_PENDING,
    DOM_CLEAR_STATE_DATA,
    DOM_DOM_CLEAR_STATE_DATA,
    DOM_DELEGATED_DOM_CLEAR_STATE_DATA
} from "../actions/domainsActions";

const initialState = {
    dom: {
        done: true,
        data: {
            domains: [],
            totalItems: 0
        },
        error: null,
        isNameAlreadyExist: false,
        isNameValidated: false,
        isInvalidDomainName: false
    },
    logs: {
        done: false,
        data: [],
        error: null
    },
    domById: {
        done: true,
        data: [],
        error: null
    },
    domDelegated: {
        done: false,
        data: {
            domains: [],
            totalItems: 0
        },
        error: null,
    },
    domPending: {
        done: false,
        data: {
            domains: [],
            totalItems: 0
        },
        error: null
    }
};

const domReducer = (state = initialState, action) => {
    switch (action.type) {
        case DOM_FETCH_DATA:
            return {
                ...state,
                dom: {
                    ...state.dom,
                    data: {domains: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case DOM_DONE:
            return {...state, dom: {...state.dom, done: true, error: null}};
        case DOM_PENDING:
            return {...state, dom: {...state.dom, done: false, error: null}};
        case DOM_ERROR:
            return {...state, dom: {...state.dom, done: true, error: action.payload}};
        case DOM_ERROR_CLEAR:
            return {...state, dom: {...state.dom, error: null}};
        case DOM_NAME_VALIDATED:
            return {
                ...state,
                dom: {...state.dom, isNameValidated: true, isNameAlreadyExist: false, isInvalidDomainName: false}
            };
        case DOM_NAME_ALREADY_EXISTS:
            return {...state, dom: {...state.dom, isNameValidated: true, isNameAlreadyExist: true}};
        case DOM_INVALID_DOMAIN_NAME:
            return {...state, dom: {...state.dom, isNameValidated: true, isInvalidDomainName: true}};
        case DOM_CLEAR_VALIDATION:
            return {
                ...state,
                dom: {...state.dom, isNameAlreadyExist: false, isInvalidDomainName: false, isNameValidated: false}
            };
        case DOM_FETCH_LOGS:
            return {...state, logs: {...state.logs, data: action.payload.response}}
        case DOM_FETCH_LOGS_DONE:
            return {...state, logs: {...state.logs, done: true, error: null}}
        case DOM_FETCH_LOGS_ERROR:
            return {...state, logs: {...state.logs, done: true, error: action.payload}}
        case DOM_FETCH_LOGS_PENDING:
            return {...state, logs: {...state.logs, done: false, error: null}}
        case DOM_DELEGATED_FETCH_DATA:
            return {
                ...state,
                domDelegated: {
                    ...state.domDelegated,
                    data: {domains: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case DOM_DELEGATED_DONE:
            return {...state, domDelegated: {...state.domDelegated, done: true, error: null}};
        case DOM_DELEGATED_PENDING:
            return {...state, domDelegated: {...state.domDelegated, done: false, error: null}};
        case DOM_DELEGATED_ERROR:
            return {...state, domDelegated: {...state.domDelegated, done: true, error: action.payload}};
        case DOM_PENDING_FETCH_DATA:
            return {
                ...state,
                domPending: {
                    ...state.domPending,
                    data: {domains: action.payload.response, totalItems: action.payload.totalRows}
                }
            };
        case DOM_PENDING_DONE:
            return {...state, domPending: {...state.domPending, done: true, error: null}};
        case DOM_PENDING_PENDING:
            return {...state, domPending: {...state.domPending, done: false, error: null}};
        case DOM_PENDING_ERROR:
            return {...state, domPending: {...state.domPending, done: true, error: action.payload}};
        case DOM_PENDING_ERROR_CLEAR:
            return {...state, domPending: {...state.domPending, error: null}};
        case DOM_BY_ID_FETCH_DATA:
            return {...state, domById: {...state.domById, data: action.payload.response}};
        case DOM_BY_ID_DONE:
            return {...state, domById: {...state.domById, done: true, error: null}};
        case DOM_BY_ID_ERROR:
            return {...state, domById: {...state.domById, done: true, error: action.payload}};
        case DOM_BY_ID_PENDING:
            return {...state, domById: {...state.domById, done: false, error: null}};
        case DOM_BY_ID_CLEAR:
            return {...state, domById: initialState.domById};
        case DOM_DOM_CLEAR_STATE_DATA:
            return {...state, dom: initialState.dom};
        case DOM_DELEGATED_DOM_CLEAR_STATE_DATA:
            return {...state, domDelegated: initialState.domDelegated};
        case DOM_CLEAR_STATE_DATA:
            return initialState;
        default:
            return state;
    }
};

export default domReducer;