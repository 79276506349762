import React from "react";
import PropTypes from "prop-types";
import {Route, Redirect} from "react-router-dom";
import {isEmpty} from "lodash";

/**
 * component that handles the private routes (with role based)
 */
const PrivateRoute = props => {
    const {component: Component, isOrgPage, routeRoles, userRoles, ...rest} = props;

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isEmpty(routeRoles)) {
                    if (!isEmpty(userRoles)) {
                        if (routeRoles.some(routeRole => userRoles.some(userRole => routeRole === userRole))) {
                            if (!isOrgPage) {
                                if (localStorage.getItem('organizationId')) {
                                    return <Component {...props} />;
                                } else {
                                    return <Redirect to="/organisatie"/>;
                                }
                            }
                            return <Component {...props} />;
                        }
                        return <Redirect to="/forbidden"/>;
                    } else {
                        if (localStorage.getItem('organizationId')) {
                            return <Redirect to="/forbidden"/>;
                        } else {
                            return <Redirect to="/organisatie"/>;
                        }
                    }
                } else {
                    if (!isOrgPage) {
                        if (!isEmpty(userRoles)) {
                            if (localStorage.getItem('organizationId')) {
                                return <Component {...props} />;
                            } else {
                                return <Redirect to="/organisatie"/>;
                            }
                        } else {
                            if (localStorage.getItem('organizationId')) {
                                return <Redirect to="/forbidden"/>;
                            } else {
                                return <Redirect to="/organisatie"/>;
                            }
                        }
                    }
                    return <Component {...props} />;
                }
            }}
        />
    );
};

PrivateRoute.propTypes = {
    /**
     * check if user its in the organization page.
     */
    isOrgPage: PropTypes.bool.isRequired,
    /**
     * array of route roles that need to satisfy in order to access the page.
     */
    routeRoles: PropTypes.array,
    /**
     * array of authenticated user roles.
     */
    userRoles: PropTypes.array
};

PrivateRoute.defaultProps = {
    isOrgPage: false,
    routeRoles: null,
    userRoles: null
};

export default PrivateRoute;