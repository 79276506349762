import {combineReducers} from "redux";
import {reducer as formReducer} from "redux-form";
import themeReducer from "./themeReducer";
import orgReducer from "./organizationReducer";
import cerReducer from "./certificatesReducer";
import cerTempReducer from "./certificateTemplatesReducer";
import appReducer from "./applicationsReducer";
import domReducer from "./domainsReducer";
import valRulesReducer from "./validationRulesReducer";
import emaReducer from "./emailSettingsReducer";
import paramReducer from "./parameterReducer";
import whitelistOriginReducer from "./whitelistOriginReducer";

export default combineReducers({
    form: formReducer,
    theme: themeReducer,
    org: orgReducer,
    cer: cerReducer,
    cerTemp: cerTempReducer,
    app: appReducer,
    dom: domReducer,
    valRules: valRulesReducer,
    ema: emaReducer,
    param: paramReducer,
    whitelist: whitelistOriginReducer
});