import React from "react";
import PropTypes from "prop-types";
/**
 * Add a separator line between PopoverItem component if needed.
 */
const PopoverSeparator = ({className}) => {
    return <li role="none" className={`vl-popover__link-list__separator${className ? ` ${className}` : ""}`}/>;
};

PopoverSeparator.propTypes = {
    /**
     * [Optional] add additional classes to popover separator.
     */
    className: PropTypes.string
};

PopoverSeparator.defaultProps = {
    className: null
};

export default PopoverSeparator;