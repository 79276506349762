import React from "react";
import PropTypes from "prop-types";

/**
 * A popover is a new, usually smaller window that appears above other content onscreen.
 * Use a popover to give additional information to the user or to ask for user interaction.
 * You can close a popover by clicking outside the popover window, or by clicking the same icon that opens the popover.
 * @see Webcomponent: <a href="https://overheid.vlaanderen.be/webuniversum/v3/documentation/js-components/vl-ui-popover" target="_blank">Popover</a>
 *
 * @example ../../../styleguide/molecules/Popover.md
 */
class Popover extends React.Component {
    static propTypes = {
        /**
         * [Optional] label to display to open the popover.
         */
        label: PropTypes.node.isRequired,
        /**
         * [Required] the position of popover should open.
         */
        position: PropTypes.oneOf([
            "left", "right"
        ]).isRequired,
        /**
         * [Optional] disable the native icon.
         */
        isCustomIcon: PropTypes.bool,
        /**
         * [Required] component that will be displayed in the popover list.
         */
        children: PropTypes.node,
        /**
         * [Optional] add additional classes to Popover.
         */
        popoverStyle: PropTypes.object,
        /**
         * [Optional] callback to parent component if popover is opened ou not.
         */
        isOpened: PropTypes.func,
        /**
         * [Optional] flag the popover to close.
         */
        close: PropTypes.bool,
        /**
         * [Optional] add a custom position to popover arrow icon.
         */
        customIconPosition: PropTypes.object,
        /**
         * [Optional] add additional classes to popover.
         */
        className: PropTypes.string
    };

    static defaultProps = {
        label: "",
        position: "left",
        isCustomIcon: false,
        children: "Popover",
        popoverStyle: {},
        isOpened: () => null,
        close: false,
        customIconPosition: {
            bottom: "0",
            left: "unset",
            right: "1.5rem"
        },
        className: ""
    };

    state = {
        opened: false,
        style: {
            position: "absolute",
            top: "3.5rem",
            right: this.props.position === "left" ? "-1.5rem" : "unset",
            left: this.props.position === "right" ? "-1.5rem" : "unset",
        }
    };

    container = React.createRef();

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.close !== this.props.close && this.props.close) {
            this.setState({opened: false});
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.container.current && !this.container.current.contains(event.target)) {
            this.setState({opened: false}, () => this.props.isOpened(this.state.opened));
        }
    };

    render() {
        const {label, position, isCustomIcon, children, className, popoverStyle, isOpened, customIconPosition} = this.props;
        const {opened, style} = this.state;

        return (
            <div style={popoverStyle}
                 className={`vl-popover vl-popover--align-${position}${this.state.opened ? ' js-vl-popover--open' : ''}${className ? ` ${className}` : ''}`}
                 ref={this.container}>
                <button
                    className="js-vl-popover__toggle vl-popover__toggle"
                    type="button"
                    aria-expanded={opened}
                    onClick={() => this.setState(prevState => ({opened: !prevState.opened}), () => isOpened(this.state.opened))}
                >
                    {label}
                    {!isCustomIcon &&
                    <i className="vl-vi vl-vi-u-badge vl-vi-u-badge--grey vl-vi-u-badge--small vl-vi-arrow vl-vi-u-90deg"
                       aria-hidden="true"/>}
                </button>
                <div className="vl-popover__content" style={style}>
                    <div style={{position: "relative"}}>
                        <div className="vl-popover__arrow" style={{position: "absolute", top: customIconPosition.top, bottom: customIconPosition.bottom, left: customIconPosition.left, right: customIconPosition.right}}/>
                    </div>
                    <div className="vl-popover__content-overflow" style={{maxHeight: "30rem"}}>
                        <ul className="vl-popover__link-list vl-u-align-left">{children}</ul>
                        <button className="vl-popover__close-btn vl-vi vl-vi-cross" tabIndex="0">
                            <span className="vl-u-visually-hidden">Close popover</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Popover;