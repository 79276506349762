import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

/**
 * This input allows for a checkbox and a label (simple or complex) to be displayed.
 * @see Webcomponent: <a href="https://overheid.vlaanderen.be/webuniversum/v3/documentation/forms/vl-ui-checkbox" target="_blank">Checkbox</a>
 *
 * @example ../../../styleguide/form/Checkbox.md
 */
const Checkbox = props => {
    const {
        label,
        isBlock,
        isDisabled,
        isSingle,
        isSwitch,
        isCentered,
        input,
        meta: {dirty, error},
        className,
        defaultChecked,
        onClick
    } = props;
    const {t} = useTranslation('validations');

    useEffect(() => {
        defaultChecked && input.onChange(defaultChecked);
    }, []);

    return (
        isSwitch ?
            <div className={`vl-checkbox--switch__wrapper${className ? ` ${className}` : ''}`}>
                <input
                    id={input.name}
                    type="checkbox"
                    className="vl-checkbox--switch"
                    disabled={isDisabled}
                    {...input}
                    checked={input.value}
                    onClick={onClick}
                />
                <label className={`vl-checkbox__label ${isCentered ? ' vl-ui-scroll-list__element' : ''}`} htmlFor={input.name}>
                    <span className="vl-checkbox--switch__label">
                        <span aria-hidden="true"/>
                    </span>
                </label>
            </div>
            :
            <>
                <label
                    className={`vl-checkbox${isBlock ? ' vl-checkbox--block' : ''}${isDisabled ? ' vl-checkbox--disabled' : ''}${isSingle ? ' vl-checkbox--single' : ''}${dirty && error && error !== 'required' ? ' vl-checkbox--error' : ''}${className ? ` ${className}` : ''}`}
                    htmlFor={input.name}>
                    <input
                        id={input.name}
                        type="checkbox"
                        className="vl-checkbox__toggle"
                        disabled={isDisabled}
                        {...input}
                        checked={input.value}
                        onClick={onClick}
                    />
                    <div className="vl-checkbox__label">
                        <i className={`vl-checkbox__box ${isCentered ? ' vl-ui-scroll-list__element' : ''}`}
                           aria-hidden="true"/>
                        {isSingle ? <span className="vl-u-visually-hidden">{label}</span> : label}
                    </div>
                </label>
                {dirty && error && error !== 'required' &&
                <p className="vl-form__error" id={`textarea-field-${input.name}-error`}>{t(`${error}`)}</p>}
            </>
    );
};

Checkbox.propTypes = {
    /**
     * metadata props received from redux-form to validate checkbox component.
     * @ignore
     */
    meta: PropTypes.shape({
        dirty: PropTypes.bool,
        error: PropTypes.string
    }).isRequired,
    /**
     * props received from redux-form to connect checkbox component to Redux.
     * @ignore
     */
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string
    }).isRequired,
    /**
     * [Required] label to display with checkbox.
     */
    label: PropTypes.node.isRequired,
    /**
     * [Optional] check if checkbox is a block element and add the class 'vl-checkbox--block'.
     */
    isBlock: PropTypes.bool,
    /**
     * [Optional] check if radio is disabled and add the class 'vl-checkbox--disabled'.
     */
    isDisabled: PropTypes.bool,
    /**
     * [Optional] single variant of a checkbox button that is shown without a label.
     */
    isSingle: PropTypes.bool,
    /**
     * [Optional] checkbox variant displayed as a switch.
     */
    isSwitch: PropTypes.bool,
    /**
     * [Optional] vertical align checkbox content to center.
     */
    isCentered: PropTypes.bool,
    /**
     * [Optional] add additional classes to checkbox.
     */
    className: PropTypes.string,
    /**
     * [Optional] checks if checkboxes is active by default.
     */
    defaultChecked: PropTypes.bool,
    /**
     * [Optional] function callback when the user clicks the checkbox.
     */
    onClick: PropTypes.func
};

Checkbox.defaultProps = {
    meta: null,
    input: null,
    label: "Checkbox button",
    isBlock: false,
    isDisabled: false,
    isSingle: false,
    isSwitch: false,
    isCentered: false,
    className: null,
    defaultChecked: false
};

export default Checkbox;