import {applyMiddleware, createStore, compose} from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers/";

let store;

if (!window.location.port && typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () {
    };
    store = createStore(reducers, applyMiddleware(reduxThunk));
} else {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk)));
}

export default store;