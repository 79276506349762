import {
    CER_TEMP_FETCH_DATA,
    CER_TEMP_DONE,
    CER_TEMP_ERROR,
    CER_TEMP_PENDING,
    CER_TEMP_ERROR_CLEAR,
    CER_TEMP_BY_DOMAIN_FETCH_DATA,
    CER_TEMP_BY_DOMAIN_PENDING,
    CER_TEMP_BY_DOMAIN_ERROR,
    CER_TEMP_BY_DOMAIN_DONE,
    CER_CLEAR_STATE_DATA
} from "../actions/certificateTemplatesActions";

const initialState = {
    cerTemp: {
        done: false,
        data: {
            certificateTemplates: [],
            totalItems: 0
        },
        error: null,
    },
    cerTempByDomain: {
        done: false,
        data: {
            certificateTemplates: []
        },
        error: null,
    },
};

const cerTempReducer = (state = initialState, action) => {
    switch (action.type) {
        case CER_TEMP_FETCH_DATA:   
            return {
                ...state,
                cerTemp: {
                    ...state.cerTemp,
                    data: {
                        certificateTemplates: action.payload.response,
                        totalItems: action.payload.totalRows
                    }
                }
            };
        case CER_TEMP_DONE:
            return {...state, cerTemp: {...state.cerTemp, done: true, error: null}};
        case CER_TEMP_PENDING:
            return {...state, cerTemp: {...state.cerTemp, done: false, error: null}};
        case CER_TEMP_ERROR:
            return {...state, cerTemp: {...state.cerTemp, done: true, error: action.payload}};
        case CER_TEMP_ERROR_CLEAR:
            return {...state, cerTemp: {...state.cerTemp, error: null}};
        case CER_TEMP_BY_DOMAIN_FETCH_DATA:
            return {
                ...state,
                cerTempByDomain: {
                    ...state.cerTempByDomain,
                    data: {
                        certificateTemplates: action.payload.response
                    }
                }
            };
        case CER_TEMP_BY_DOMAIN_DONE:
            return {...state, cerTempByDomain: {...state.cerTempByDomain, done: true, error: null}};
        case CER_TEMP_BY_DOMAIN_PENDING:
            return {...state, cerTempByDomain: {...state.cerTempByDomain, done: false, error: null}};
        case CER_TEMP_BY_DOMAIN_ERROR:
            return {...state, cerTempByDomain: {...state.cerTempByDomain, done: true, error: action.payload}};
        case CER_CLEAR_STATE_DATA:
            return initialState;
        default:
            return state;
    }
};

export default cerTempReducer;