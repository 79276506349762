import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

/**
 * Adds a footer element for the modal. Use it together with Modal component.
 */
const ModalFooter = ({btnLabel, btnActionType, onBtnActionClick, btnActionDisabled, btnActionIsLoading, onModalClose, btnCancelHidden}) => {
    const {t} = useTranslation('common');

    return (
        <div className="vl-action-group">
            <button type={btnActionType} className={`vl-button${btnActionDisabled ? ' vl-button--disabled' : ''}${btnActionIsLoading ? ' vl-button--loading' : ''}`}
                    onClick={onBtnActionClick} disabled={btnActionDisabled || btnActionIsLoading}>
                <span className="vl-button__label">{btnLabel}</span>
            </button>
            {
                !btnCancelHidden &&
                <button type="button" className="vl-link" onClick={onModalClose}>
                    <i
                        className="vl-link__icon vl-link__icon--before vl-vi vl-vi-cross"
                        aria-hidden="true"
                    />
                    {t('modal->btnClose')}
                </button>
            }
        </div>
    );
};

ModalFooter.propTypes = {
    /**
     * [Required] label to display with the action button.
     */
    btnLabel: PropTypes.string.isRequired,
    /**
     * [Optional] type of action button.
     */
    btnActionType: PropTypes.string,
    /**
     * [Optional] function callback when user click the action button.
     */
    onBtnActionClick: PropTypes.func,
    /**
     * [Optional] disables the action button.
     */
    btnActionDisabled: PropTypes.bool,
    /**
     * [Optional] show a loading state button.
     */
    btnActionIsLoading: PropTypes.bool,
    /**
     * [Required] function callback when user click on cancel button.
     */
    onModalClose: PropTypes.func.isRequired,
    /**
     * [Optional] hides the cancel button, useful when we only need the action button (e.g.: modal with only messages).
     */
    btnCancelHidden: PropTypes.bool,
};

ModalFooter.defaultProps = {
    btnLabel: "Action button",
    btnActionType: "button",
    onBtnActionClick: () => null,
    btnActionDisabled: false,
    btnActionIsLoading: false,
    onModalClose: () => null
};

export default ModalFooter;