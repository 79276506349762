import axios from "axios";
import moment from "moment";
import {isEmpty} from "lodash";
import {APP_DOMAIN_NOT_FOUND, APP_INVALID_NAME, APP_NAME_ALREADY_EXISTS} from "./applicationsActions";
import {CER_CSR_INVALID_CERTIFICATE} from "./certificatesActions";
import {DOM_INVALID_DOMAIN_NAME, DOM_NAME_ALREADY_EXISTS} from "./domainsActions";
import {THEME_SET_SYSTEM_ERROR_MESSAGE} from "./themeActions";

export const COMMON_SET_ERROR_MESSAGE = 'COMMON_SET_ERROR_MESSAGE';

let apiURL = process.env.REACT_APP_API_URL;

/**
 * After an error occurs, it wll be handled on handleErrors, dispatching the correct error message received from the 'error' parameter
 *
 * @param {object} error the error object that contains information about the error that occurred
 * @param {string} field the field that error occurred
 */
export const handleErrors = (error, field) => async dispatch => {
    const timeOfError = moment.utc().format('YYYY-MM-DD HH:mm:ss') + ' UTC';
    const setErrorMessage = THEME_SET_SYSTEM_ERROR_MESSAGE;
    if (error.response?.status === 403) {
        dispatch({type: field});
        dispatch({
            type: setErrorMessage,
            payload: {
                errorCode: 403,
                field: field,
                errorMessage: {
                    message: "You don't have enough permissions to perform this action.", error: error.response.data
                },
                time: timeOfError
            }
        });

        return;
    }

    if (error.response?.status === 401) {
        dispatch({type: field});
        dispatch({
            type: setErrorMessage,
            payload: {
                customTitle: "titleUnauthorized",
                customMessage: {
                    errorCode: 401,
                    message: "messageUnauthorized"
                },
                customBtnLabel: "btnUnauthorized"
            }
        });
        return;
    }

    if (error.response?.data.errorType) {
        const errorData = error.response.data;

        if (errorData.errorType === "PKI_ERROR" && (
            errorData.errorSubType.includes("VALIDITY.NOT_AFTER:_MUST_BE_BEFORE_OR_EQUAL_TO_CA_CERTIFICATE'S_NOT_AFTER:") ||
            errorData.errorSubType.includes("INVALID_CERTIFICATE_REQUEST:_NOT_AFTER_DATE_MUST_BE_BEFORE_OR_EQUAL_TO_CA_CERTIFICATE'S_NOT_AFTER_DATE:")
        )) {
            dispatch({
                type: setErrorMessage,
                payload: {
                    customTitle: "titleCAValidityDate",
                    customMessage: {
                        errorCode: 400,
                        message: "messageCAValidityDate"
                    }
                }
            });
        } else {
            switch (errorData.errorType) {
                case "FUNCTIONAL_ERROR":
                    switch (errorData.errorSubType) {
                        //applications error
                        case "APPLICATION_NAME_ALREADY_EXISTS":
                            dispatch({type: APP_NAME_ALREADY_EXISTS});
                            break;
                        case "DOMAIN_NOT_FOUND":
                            dispatch({type: APP_DOMAIN_NOT_FOUND});
                            break;
                        //certificates error
                        case "CSV_GENERATION_FAIL":
                            dispatch({type: field});
                            dispatch({
                                type: setErrorMessage,
                                payload: {...errorData, field: field, time: timeOfError}
                            });
                            break;
                        case "CERTIFICATE_DATA_NOT_FOUND":
                            dispatch({type: field});
                            dispatch({
                                type: setErrorMessage,
                                payload: {...errorData, field: field, time: timeOfError}
                            });
                            break;
                        case "INVALID_CERTIFICATE_REQUEST":
                            dispatch({
                                type: CER_CSR_INVALID_CERTIFICATE,
                                payload: {...errorData, field: field, time: timeOfError}
                            });
                            break;
                        //this one is common between app and certificate
                        case "INVALID_APPLICATION_NAME":
                            field === "APP_ERROR" ?
                                dispatch({type: APP_INVALID_NAME})
                                :
                                dispatch({
                                    type: CER_CSR_INVALID_CERTIFICATE,
                                    payload: {...errorData, field: field, time: timeOfError}
                                });
                            break;
                        case "INVALID_CN_NAME":
                        case "ORG_DOMAINS_NOT_FOUND":
                        case "SAN_DOMAINS_NOT_FOUND":
                        case "CERTIFICATE_TEMPLATE_NOT_FOUND":
                        case "RSA_INVALID_KEY_LENGTH":
                        case "ECC_INVALID_KEY_LENGTH":
                        case "ERROR_CALCULATING_KEY_LENGTH":
                        case "ERROR_PARSING_CSR":
                        case "INVALID_SAN_LIST":
                        case "MISSING_COMMON_NAME_FIELD":
                        case "MISSING_ORGANIZATION_FIELD":
                        case "INVALID_COUNTRY_NAME":
                        case "INVALID_ORGANIZATION_LENGTH":
                        case "MISSING_COUNTRY_FIELD":
                        case "UNMATCHING_CN_NAMES":
                        case "SAN_MAX_EXCEEDED":
                        case "HASH_ALGORITHM_NOT_SUPPORTED":
                        case "INCOMPATIBLE_KEY_ALGORITHM_CERTIFICATE_TEMPLATE":
                            dispatch({
                                type: CER_CSR_INVALID_CERTIFICATE,
                                payload: {...errorData, field: field, time: timeOfError}
                            });
                            break;
                        //domains error
                        case "DOMAIN_NAME_ALREADY_EXISTS":
                            dispatch({type: DOM_NAME_ALREADY_EXISTS});
                            break;
                        case "INVALID_DOMAIN_NAME":
                            dispatch({type: DOM_INVALID_DOMAIN_NAME});
                            break;
                        case "ERROR_PARSING_CERTIFICATE":
                            dispatch({
                                type: setErrorMessage,
                                payload: {
                                    customTitle: "errorParsingCertificateTitle",
                                    customMessage: {
                                        errorCode: 400,
                                        message: "errorParsingCertificateMessage"
                                    },
                                    customBtnLabel: "btnErrorParsingCertificate"
                                }
                            });
                            break;
                        case "CERTIFICATE_FROM_DCBAAS":
                            dispatch({
                                type: setErrorMessage,
                                payload: {
                                    customTitle: "errorCertificateFromDCBaaSNotImportableTitle",
                                    customMessage: {
                                        errorCode: 400,
                                        message: "errorCertificateFromDCBaaSNotImportableMessage"
                                    },
                                    customBtnLabel: "btnCertificateFromDCBaaSNotImportable"
                                }
                            });
                            break;
                        case "CERTIFICATE_IMPORTED_ALREADY_EXIST":
                            dispatch({
                                type: setErrorMessage,
                                payload: {
                                    customTitle: "errorCertificateImportedAlreadyExistTitle",
                                    customMessage: {
                                        errorCode: 400,
                                        message: "errorCertificateImportedAlreadyExistMessage"
                                    },
                                    customBtnLabel: "btnCertificateImportedAlreadyExist"
                                }
                            });
                            break;
                        default:
                            dispatch({type: field});
                            dispatch({
                                type: setErrorMessage,
                                payload: {...errorData, field: field, time: timeOfError}
                            });
                    }
                    break;
                case "SQL_ERROR":
                    dispatch({type: setErrorMessage, payload: {...errorData, field: field, time: timeOfError}});
                    break;
                case "PKI_ERROR":
                    switch (errorData.errorSubType) {
                        case "PUBLIC_KEY:_IS_INVALID":
                            dispatch({
                                type: setErrorMessage,
                                payload: {
                                    customTitle: "titleCSRInvalid",
                                    customMessage: {
                                        errorCode: 500,
                                        message: "messageCSRInvalid"
                                    }
                                }
                            })
                            break;
                        case "ERROR_MARSHALING_RAW_SUBJECT_DN":
                            dispatch({
                                type: setErrorMessage,
                                payload: {
                                    customTitle: "titleCNTooLong",
                                    customMessage: {
                                        errorCode: 500,
                                        message: "messageCNTooLong"
                                    }
                                }
                            })
                            break;
                        case "INVALID_SERIAL_NUMBER_FORMAT":
                            dispatch({
                                type: setErrorMessage,
                                payload: {
                                    customTitle: "titleInvalidSerialNumberFormat",
                                    customMessage: {
                                        errorCode: 500,
                                        message: "messageInvalidSerialNumberFormat",
                                        additionalMessage: errorData.errorSubType
                                    }
                                }
                            })
                            break;
                        default:
                            dispatch({type: setErrorMessage, payload: {...errorData, field: field, time: timeOfError}});
                            break;
                    }
                    break;
                case "GENERIC_ERROR":
                    dispatch({type: field});
                    dispatch({type: setErrorMessage, payload: {...errorData, field: field, time: timeOfError}});
                    break;
                default:
                    dispatch({type: field});
                    dispatch({type: setErrorMessage, payload: {...errorData, field: field, time: timeOfError}});
            }
        }
    } else {
        dispatch({
            type: setErrorMessage,
            payload: {errorMessage: error.response?.data.message || "UNKNOWN ERROR", field: field, time: timeOfError}
        });
    }
};

/**
 *
 * @param {number} organizationId the id of organization we want to search
 * @param {number} accountId the id of user account we want to search
 * @returns {object} returns a object which contains the user info
 */
export const getUserInformation = (organizationId, accountId) => async dispatch => {
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const userResponse = await axios.post(apiURL + '/user/information', {
            organization_id: orgId,
            queryParameters: {
                organization_id: organizationId,
                accountId: accountId
            }
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(userResponse.data.response)) {
            return userResponse.data.response;
        }
        return {
            "name": null,
            "last_name": null,
            "email_address": "support@dcbaas.be",
        }
    } catch (err) {
        dispatch(handleErrors(err, 'USER_INFORMATION_ERROR'));
    }
};

/**
 * Fetch organizations to associate the names of each organization
 *
 * @param {object} dataObject the object where contains the data fetch information
 * @param {string} compareField the object property name inside dataObject we want to compare with
 */
export const getOrgNames = (dataObject, compareField) => async dispatch => {
    try {
        let data = [], dataCopy = [], orgIds = [];
        await Promise.all(dataObject.response.map(object => orgIds.push(object[compareField])));

        //remove duplicate values
        orgIds = [...new Set(orgIds)];
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const orgResponse = await axios.post(apiURL + '/organization/search', {
            organization_id: orgId,
            queryParameters: {id: orgIds},
            returnValues: ["id", "name", "organization_code"]
        }, {headers: {Authorization: authorizationToken}});
        if (orgResponse) {
            await Promise.all(dataObject.response.map(async object =>
                await Promise.all(orgResponse.data.response.map(org =>
                        object[compareField] === org.id && data.push({
                            ...object,
                            organization: org
                        }))
                )));
            dataCopy['response'] = data;
            dataCopy['totalRows'] = dataObject.totalRows;
            return dataCopy;
        }
    } catch (err) {
        dispatch(handleErrors(err, 'USER_INFORMATION_ERROR'));
    }
};