import axios from "axios";
import {isEmpty, cloneDeep} from "lodash";
import {handleErrors, getUserInformation, getOrgNames} from "./commonActions";

export const APP_FETCH_DATA = 'APP_FETCH_DATA';
export const APP_PENDING = 'APP_PENDING';
export const APP_DONE = 'APP_DONE';
export const APP_ERROR = 'APP_ERROR';
export const APP_BY_ID_FETCH_DATA = 'APP_BY_ID_FETCH_DATA';
export const APP_BY_ID_PENDING = 'APP_BY_ID_PENDING';
export const APP_BY_ID_DONE = 'APP_BY_ID_DONE';
export const APP_BY_ID_ERROR = 'APP_BY_ID_ERROR';
export const APP_BY_ID_CLEAR = 'APP_BY_ID_CLEAR';
export const APP_ERROR_CLEAR = 'APP_ERROR_CLEAR';
export const APP_VALID_NAME = 'APP_VALID_NAME';
export const APP_INVALID_NAME = 'APP_INVALID_NAME';
export const APP_NAME_ALREADY_EXISTS = 'APP_NAME_ALREADY_EXISTS';
export const APP_DOMAIN_NOT_FOUND = 'APP_DOMAIN_NOT_FOUND';
export const APP_IS_OWN_DOMAIN = 'APP_IS_OWN_DOMAIN';
export const APP_IS_NOT_OWN_DOMAIN = 'APP_IS_NOT_OWN_DOMAIN';
export const APP_CLEAR_VALIDATION = 'APP_CLEAR_VALIDATION';
export const APP_ERROR_SUBMIT = 'APP_ERROR_SUBMIT';
export const APP_PENDING_FETCH_DATA = 'APP_PENDING_FETCH_DATA';
export const APP_PENDING_PENDING = 'APP_PENDING_PENDING';
export const APP_PENDING_DONE = 'APP_PENDING_DONE';
export const APP_PENDING_ERROR = 'APP_PENDING_ERROR';
export const APP_DELEGATED_FETCH_DATA = 'APP_DELEGATED_FETCH_DATA';
export const APP_DELEGATED_PENDING = 'APP_DELEGATED_PENDING';
export const APP_DELEGATED_DONE = 'APP_DELEGATED_DONE';
export const APP_DELEGATED_ERROR = 'APP_DELEGATED_ERROR';
export const APP_FETCH_LOGS = 'APP_FETCH_LOGS';
export const APP_FETCH_LOGS_DONE = 'APP_FETCH_LOGS_DONE';
export const APP_FETCH_LOGS_ERROR = 'APP_FETCH_LOGS_ERROR';
export const APP_FETCH_LOGS_PENDING = 'APP_FETCH_LOGS_PENDING';
export const APP_CLEAR_STATE_DATA = 'APP_CLEAR_STATE_DATA';
export const APP_APP_CLEAR_STATE_DATA = 'APP_APP_CLEAR_STATE_DATA';
export const APP_DELEGATED_CLEAR_STATE_DATA = 'APP_DELEGATED_CLEAR_STATE_DATA';

let apiURL = process.env.REACT_APP_API_URL, apiUrlES = process.env.REACT_APP_API_ES_URL;

//initial values for the pagination
const initialPagination = {
    currentPage: 1,
    currentRowsPerPage: 10
};

//initial values for filtering table results
const initialFilters = {
    searchString: "",
    appIds: [],
    status: ["active", "requested", "refused", "expired"],
    sort: {
        order: "DESC",
        orderBy: "requested_on"
    }
};

//initial values for filtering delegated table results
const delegatedInitialFilters = {
    status: ["active", "expired"],
    sort: {
        order: "DESC",
        orderBy: "delegation_end_date"
    }
};

//initial values for filtering pending table results
const pendingInitialFilters = {
    status: ["requested"],
    sort: {
        order: "DESC",
        orderBy: "requested_on"
    }
};

/**
 * Fetches all the selected application logs
 *
 * @param {number} applicationId the application id
 */
export const fetchApplicationLogs = applicationId => async dispatch => {
    dispatch({type: APP_FETCH_LOGS_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/logs/application', {
            organization_id: orgId,
            queryParameters: {
                application_id: applicationId,
                order: "DESC",
                orderBy: "log_timestamp",
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            dispatch({type: APP_FETCH_LOGS, payload: response.data});
        }
        dispatch({type: APP_FETCH_LOGS_DONE});
    } catch (err) {
        dispatch(handleErrors(err, APP_FETCH_LOGS_ERROR));
    }
};

/**
 * fetch all the applications data
 *
 * @param {object} pagination receive an object with the pagination configurations
 * @param {object} filters receive an object with the filters configurations
 */
export const fetchApplications = (pagination = initialPagination, filters = initialFilters) => async dispatch => {
    dispatch({type: APP_PENDING});
    try {
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);
        const query = {
            organization_id: orgId,
            queryParameters: {
                organization_id: orgId,
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                order: filtersCopy.sort.order,
                orderBy: filtersCopy.sort.orderBy,
                status: filtersCopy.status,
                id: filtersCopy.searchString ? filtersCopy.appIds : [],
                withInfo: true
            },
            returnValues: [
                "id",
                "name",
                "status",
                "is_delegated",
                "org_delegated",
                "requestor_org",
                "requested_on",
                "organization_id",
                "domain_owner",
                "delegation_end_date",
                "reason",
                "refusal_reason",
                "can_delete",
                "organization_name"
            ]
        }

        const appResponse = await axios.post(apiURL + '/application/self', query, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(appResponse?.data.response)) {
            const data = await dispatch(getOrgNames(appResponse.data, "domain_owner"));
            dispatch({type: APP_FETCH_DATA, payload: data});
        } else {
            dispatch({type: APP_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: APP_DONE});
    } catch (err) {
        dispatch(handleErrors(err, APP_ERROR));
    }
};

/**
 * fetch all the filtered applications
 *
 * @param {object} pagination receive an object with the pagination configurations
 * @param {object} filters receive an object with the filters configurations
 * @param {boolean} allRequests flag if want all the pending requests (for admin page)
 */
export const filterFetchApplications = (pagination = initialPagination, filters = initialFilters, allRequests = false) => async dispatch => {
    dispatch({type: allRequests ? APP_PENDING_PENDING : APP_PENDING});
    try {
        let filtersCopy = filters, appIds = [];
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));

        if (!allRequests) {
            const appResponse = await axios.post(apiURL + '/application/self', {
                organization_id: orgId,
                queryParameters: {
                    organization_id: orgId
                },
                returnValues: ["id"]
            }, {headers: {Authorization: authorizationToken}});

            await Promise.all(appResponse.data.response.map(app => appIds.push(app.id)));
        }
        const appESResponse = await axios.post(apiUrlES + '/essearch/application', {
            searchstring: filtersCopy.searchString,
            requestor_org: allRequests ? [] : orgId,
            attributes: ["name", "requested_on", "delegation_end_date", "organization_name"],
            status: filters.status,
            application_id: allRequests ? [] : appIds
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(appESResponse?.data)) {
            appIds = [];
            await Promise.all(appESResponse.data.map(app => appIds.push(parseInt(app._source.id))));
            filtersCopy.appIds = appIds;
            allRequests ? await dispatch(fetchPendingApplications(pagination, true, filtersCopy)) : await dispatch(fetchApplications(pagination, filtersCopy));
        } else {
            dispatch({
                type: allRequests ? APP_PENDING_FETCH_DATA : APP_FETCH_DATA,
                payload: {response: [], totalRows: 0}
            });
        }
        dispatch({type: allRequests ? APP_PENDING_DONE : APP_DONE});
    } catch (err) {
        dispatch(handleErrors(err, allRequests ? APP_PENDING_ERROR : APP_ERROR));
    }
};

/**
 * fetch the selected application data
 *
 * @param {number} id the id of application to fetch
 */
export const fetchApplicationById = id => async dispatch => {
    dispatch({type: APP_BY_ID_CLEAR});
    dispatch({type: APP_BY_ID_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const query = {
            organization_id: orgId,
            queryParameters: {
                organization_id: orgId,
                id: [id]
            },
            returnValues: [
                "id",
                "name",
                "status",
                "is_delegated",
                "requestor_org",
                "requested_on",
                "organization_id",
                "delegation_end_date",
                "reason",
                "can_delete"
            ]
        }

        const appResponse = await axios.post(apiURL + '/application/self', query, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(appResponse?.data.response)) {
            const data = await dispatch(getOrgNames(appResponse.data, "organization_id"));
            dispatch({type: APP_BY_ID_FETCH_DATA, payload: data});
        } else {
            dispatch(fetchApplications());
        }
        dispatch({type: APP_BY_ID_DONE});
    } catch (err) {
        dispatch(handleErrors(err, APP_BY_ID_ERROR));
    }
};

/**
 * validate the application name for the new application modal
 * @param {string} type check if its to validate or to clear
 * @param {string} name string that contain the app name to validate
 * @param {number} organizationId the id of the organization to validate
 */
export const validateAppName = (type, name, organizationId) => async dispatch => {
    dispatch({type: APP_ERROR_CLEAR});
    if (type === "clear" || !name.trim()) {
        dispatch({type: APP_CLEAR_VALIDATION});
    } else {
        dispatch({type: APP_CLEAR_VALIDATION});
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        try {
            const response = await axios.post(apiURL + '/application/validate', {
                organization_id: orgId,
                data: {
                    name: name,
                    organization_id: organizationId
                }
            }, {headers: {Authorization: authorizationToken}});
            if (response) {
                const res = response.data;
                if (res.applicationAlreadyExists) {
                    dispatch({
                        type: APP_NAME_ALREADY_EXISTS,
                        payload: {id: res.id, name: res.name, organization_code: res.organization_code}
                    });
                } else {
                    dispatch({type: APP_VALID_NAME});
                    res.isOwnDomain ? dispatch({type: APP_IS_OWN_DOMAIN}) : dispatch({type: APP_IS_NOT_OWN_DOMAIN});
                }
            }
        } catch (err) {
            dispatch(handleErrors(err, APP_ERROR));
        }
    }
};

/**
 * make a call to API to add a new application
 *
 * @param {object} values object with all the necessary input values to create a new application
 */
export const addNewApplication = values => async dispatch => {
    dispatch({type: APP_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/application/add', {
            organization_id: orgId,
            data: {
                name: values.name,
                organization_id: values.organization_id,
                duration: values.duration,
                reason: values.reason
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response;
        }
    } catch (err) {
        dispatch(handleErrors(err, APP_ERROR));
    }
};

/**
 * deletes the selected application from the list. The application itself isn't really deleted forever, it just gets flagged in order not to appear on the table.
 *
 * @param {number} id id of the application being deleted
 */
export const deleteApplication = id => async dispatch => {
    dispatch({type: APP_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/application/delete', {
            organization_id: orgId,
            data: {
                id: id
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, APP_ERROR));
    }
};

/**
 * updates the application with new information
 *
 * @param {object} values values necessary to update the application, in this case only the description is necessary.
 */
export const updateApplication = values => async dispatch => {
    dispatch({type: APP_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/application/update', {
            organization_id: orgId,
            data: {
                id: values.id,
                reason: values.reason || "",
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, APP_ERROR));
    }
};

/**
 * fetch all the pending applications data
 *
 * @param {object} pagination receive an object with the pagination configurations
 * @param {object} filters receive an object with the filters configurations
 * @param {boolean} allRequests flag if want all the pending requests (for admin page)
 */
export const fetchPendingApplications = (pagination = initialPagination, allRequests = false, filters = pendingInitialFilters) => async dispatch => {
    dispatch({type: APP_PENDING_PENDING});
    try {
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        if (filtersCopy.sort.orderBy === "requested_on") {
            filtersCopy.sort.orderBy = "COALESCE(mark_as_delegate_requested_on, requested_on)";
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);

        const pendingAppResponse = await axios.post(apiURL + '/application/search', {
            organization_id: orgId,
            queryParameters: {
                id: allRequests ? filters.appIds : undefined,
                organization_id: allRequests ? undefined : orgId,
                status: ["requested"],
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                order: filtersCopy.sort.order,
                orderBy: filtersCopy.sort.orderBy,
                withInfo: true
            },
            returnValues: [
                "id",
                "name",
                "organization_id",
                "organization_name",
                "requestor_org",
                "requestor_acc",
                "requested_by",
                "domain_owner",
                "reason",
                "requested_on",
                "duration",
                "external_friendly_name",
                "org_delegated",
                "mark_as_delegate",
                "mark_as_delegate_requested_on"
            ]
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(pendingAppResponse?.data.response)) {
            let data = await dispatch(getOrgNames(pendingAppResponse.data, "requestor_org"));
            let domainOwnerData = await dispatch(getOrgNames(pendingAppResponse.data, "domain_owner"));

            await Promise.all(data.response.map(async (app, i) => {
                const userInfoResponse = await dispatch(getUserInformation(app.requestor_org, app.requestor_acc));
                if (userInfoResponse.name) {
                    app.requestor_name = `${userInfoResponse.name} ${userInfoResponse.last_name}`;
                    app.requestor_email = userInfoResponse.email_address;
                } else {
                    app.requestor_name = app.external_friendly_name;
                }
                app.domain_owner = domainOwnerData.response[i].organization;

                if (app.mark_as_delegate) {
                    const cerResponse = await axios.post(apiURL + '/certificate/count', {
                        organization_id: orgId,
                        queryParameters: {
                            application_id: app.id,
                            status: ["active"]
                        }
                    }, {headers: {Authorization: authorizationToken}});
                    if (cerResponse) {
                        app.total_certificates = cerResponse.data.totalCertificates;
                    }
                }
                return app;
            }));

            dispatch({type: APP_PENDING_FETCH_DATA, payload: data});
        } else {
            dispatch({type: APP_PENDING_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: APP_PENDING_DONE});
    } catch (err) {
        dispatch(handleErrors(err, APP_PENDING_ERROR));
    }
};

/**
 * update the status of an application
 *
 * @param {string} type status of the application to be updated
 * @param {number} id the application id to be updated
 * @param {number} duration the duration of the approved application
 * @param {string} refusalReason the reason why the application was refused
 */
export const updatePendingApplication = (type, id, duration = 24, refusalReason = "") => async dispatch => {
    dispatch({type: APP_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + `/application/${type}`, {
            organization_id: orgId,
            data: {
                id: id,
                duration: duration,
                refusal_reason: refusalReason
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, APP_PENDING_ERROR));
    }
};

/**
 * fetch all the delegated applications data
 *
 * @param {object} pagination receive an object with the pagination configurations
 * @param {object} filters receive an object with the filters configurations
 */
export const fetchDelegatedApplications = (pagination = initialPagination, filters = delegatedInitialFilters) => async dispatch => {
    dispatch({type: APP_DELEGATED_PENDING});
    try {
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);
        const delegatedAppResponse = await axios.post(apiURL + '/application/search', {
            organization_id: orgId,
            queryParameters: {
                id: filtersCopy.appIds || undefined,
                status: filtersCopy.status,
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                order: filtersCopy.sort.order,
                orderBy: filtersCopy.sort.orderBy,
                is_delegated: true,
                organization_id: orgId,
                withInfo: true
            },
            returnValues: [
                "id",
                "name",
                "status",
                "requestor_org",
                "org_delegated",
                "delegation_start_date",
                "delegation_end_date",
                "organization_name",
                "organization_id"
            ]
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(delegatedAppResponse?.data.response)) {
            const data = await dispatch(getOrgNames(delegatedAppResponse.data, "org_delegated"));
            dispatch({type: APP_DELEGATED_FETCH_DATA, payload: data});
        } else {
            dispatch({type: APP_DELEGATED_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: APP_DELEGATED_DONE});
    } catch (err) {
        dispatch(handleErrors(err, APP_DELEGATED_ERROR));
    }
};

/**
 * fetch all the filtered delegated applications
 *
 * @param {object} pagination receive an object with the pagination configurations
 * @param {object} filters receive an object with the filters configurations
 */
export const filterFetchDelegatedApplications = (pagination = initialPagination, filters = initialFilters) => async dispatch => {
    dispatch({type: APP_DELEGATED_PENDING});
    try {
        let filtersCopy = filters, appIds = [];
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));

        const appResponse = await axios.post(apiURL + '/application/search', {
            organization_id: orgId,
            queryParameters: {
                status: filtersCopy.status,
                is_delegated: true,
                organization_id: orgId
            },
            returnValues: ["id"]
        }, {headers: {Authorization: authorizationToken}});

        await Promise.all(appResponse.data.response.map(app => appIds.push(app.id)));

        const appESResponse = await axios.post(apiUrlES + '/essearch/application', {
            searchstring: filtersCopy.searchString,
            requestor_org: [],
            attributes: ["name", "delegation_start_date", "delegation_end_date", "org_delegated_name"],
            status: filters.status,
            application_id: appIds
        }, {headers: {Authorization: authorizationToken}});
        if (!isEmpty(appESResponse?.data)) {
            appIds = [];
            await Promise.all(appESResponse.data.map(app => appIds.push(parseInt(app._source.id))));
            filtersCopy.appIds = appIds;
            await dispatch(fetchDelegatedApplications(pagination, filtersCopy));
        } else {
            dispatch({type: APP_DELEGATED_FETCH_DATA, payload: {response: [], totalRows: 0}});
        }
        dispatch({type: APP_DELEGATED_DONE});
    } catch (err) {
        dispatch(handleErrors(err, APP_DELEGATED_ERROR));
    }
};

/**
 * handle the application transfer
 *
 * @param {object} values all the data needed to transfer a application
 */
export const transferApplication = values => async dispatch => {
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/application/transfer', {
            organization_id: orgId,
            data: {
                id: values.id,
                name: values.name,
                organization_id: values.organization_id
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, APP_ERROR));
    }
};

/**
 * handle the application revoke
 *
 * @param {number} id the id of the application to be transferred
 */
export const revokeApplication = id => async dispatch => {
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/application/revoke', {
            organization_id: orgId,
            data: {
                id: id
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, APP_ERROR));
    }
};

/**
 * handle the application extension period
 *
 * @param {object} values the application to be extended
 */
export const extendApplication = values => async dispatch => {
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/application/extend', {
            organization_id: orgId,
            data: {
                id: values.id,
                duration: values.duration
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, APP_ERROR));
    }
};

/**
 * handle the application domain owner change
 *
 * @param {object} values the application to be changed
 */
export const changeApplicationDomainOwner = values => async dispatch => {
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/application/change-domain-owner', {
            organization_id: orgId,
            data: {
                id: values.id,
                domain_owner: values.domain_owner
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, APP_ERROR));
    }
};

/**
 * handle the application delegation to another organization
 *
 * @param {object} values the application to be delegated
 */
export const delegateApplication = values => async dispatch => {
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/application/delegate', {
            organization_id: orgId,
            data: {
                id: values.id,
                organization_id: values.organization_id,
                duration: values.duration
            }
        }, {headers: {Authorization: authorizationToken}});

        if (response) {
            return response;
        }
    } catch (err) {
        dispatch(handleErrors(err, APP_ERROR));
    }
};

/**
 * clear the appById data
 */
export const clearAppByIdData = () => async dispatch => {
    dispatch({type: APP_BY_ID_CLEAR});
};

/**
 * clears all the state data
 */
export const clearStateData = () => async dispatch => {
    dispatch({type: APP_CLEAR_STATE_DATA});
};

/**
 * clears all the app state data
 */
export const clearAppStateData = () => async dispatch => {
    dispatch({type: APP_APP_CLEAR_STATE_DATA});
};

/**
 * clears all the delegated app state data
 */
export const clearDelegatedAppStateData = () => async dispatch => {
    dispatch({type: APP_DELEGATED_CLEAR_STATE_DATA});
};