import {
    THEME_SET_TABS_VISIBILITY,
    THEME_SET_USER_MENU_VISIBILITY,
    THEME_USER_DATA_FETCH,
    THEME_USER_DATA_CLEAR,
    THEME_USER_DATA_DONE,
    THEME_USER_DATA_PENDING,
    THEME_USER_DATA_ERROR,
    THEME_AUTH_DONE,
    THEME_NOTIFICATION_DONE,
    THEME_NOTIFICATION_PENDING,
    THEME_NOTIFICATION_FETCH_DATA,
    THEME_NOTIFICATION_ERROR,
    THEME_SET_SYSTEM_ERROR_MESSAGE
} from "../actions/themeActions";

const initialState = {
    userData: {
        user: {
            username: null,
            roles: [],
            hasAppWideRoles: false,
            hasAdminConfigPrivilege: false
        },
        done: false,
        error: null
    },
    showTabs: true,
    showUserMenu: true,
    authDone: false,
    errorModal: {
        opened: false,
        message: null
    },
    notification: {
        done: false,
        data: {
            notifications: [],
            totalItems: 0
        },
        error: null
    },
    error: null
};

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case THEME_SET_TABS_VISIBILITY:
            return {...state, showTabs: action.payload};
        case THEME_SET_USER_MENU_VISIBILITY:
            return {...state, showUserMenu: action.payload};
        case THEME_USER_DATA_PENDING:
        case THEME_USER_DATA_CLEAR:
            return {...state, userData: initialState.userData};
        case THEME_USER_DATA_DONE:
            return {...state, userData: {...state.userData, done: true}};
        case THEME_USER_DATA_FETCH:
            return {...state, userData: {...state.userData, user: action.payload}};
        case THEME_USER_DATA_ERROR:
            return {...state, userData: {...state.userData, error: action.payload}};
        case THEME_AUTH_DONE:
            return {...state, authDone: true};
        case THEME_NOTIFICATION_PENDING:
            return {...state, notification: initialState.notification};
        case THEME_NOTIFICATION_DONE:
            return {...state, notification: {...state.notification, done: true}};
        case THEME_NOTIFICATION_FETCH_DATA:
            return {
                ...state,
                notification: {...state.notification, data: {notifications: action.payload.response, totalItems: action.payload.totalRows}}
            };
        case THEME_NOTIFICATION_ERROR:
            return {...state, notification: {...state.notification, error: action.payload}};
        case THEME_SET_SYSTEM_ERROR_MESSAGE:
            return {...state, error: action.payload};
        default:
            return state;
    }
};

export default themeReducer;