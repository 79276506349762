import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import axios from "axios";
import CryptoJS from "crypto-js";
import history from "../../history";
import qs from "qs";
import {cloneDeep} from "lodash";
import {AuthProvider} from "oidc-react";
import {connect} from "react-redux";
import {setAuth} from "../../redux/actions/themeActions";

class AuthComponent extends React.PureComponent {
    static propTypes = {
        /**
         * [Required] the discovery URL that defines the available endpoints and supported OpenID Connect functionalities.
         * The URL path ends with "/.well-known/openid-configuration".
         */
        authority: PropTypes.string.isRequired,
        /**
         * [Required] the id obtained during the application registration.
         */
        clientId: PropTypes.string.isRequired,
        /**
         * [Optional] display the content of component.
         */
        clientSecret: PropTypes.string,
        /**
         * [Required] display the content of component.
         */
        redirectUri: PropTypes.string.isRequired,
        /**
         * [Optional] indicates if want to use PKCE.
         */
        disablePKCE: PropTypes.bool,
        /**
         * [Required] determines the method by which the Authorization Code is returned.
         */
        responseMode: PropTypes.string,
        /**
         * [Optional] the grant type. Indicates which flow is being followed and the behaviour for returning tokens.
         */
        responseType: PropTypes.string,
        /**
         * [Optional] the scope "openid" must be minimally present.
         * Other scope values are optional and only the claims associated with the requested scopes will be delivered.
         * Multiple scopes are separated by a space and then URL encoded.
         */
        scope: PropTypes.string,
        /**
         * [Required] display the content of component.
         */
        children: PropTypes.node.isRequired
    };

    static defaultProps = {
        authority: null,
        clientId: null,
        clientSecret: null,
        redirectUri: null,
        disablePKCE: false,
        responseMode: "query",
        responseType: "code",
        scope: "openid",
        children: "Auth component"
    };

    base64URLEncode = (str) => {
        return CryptoJS.enc.Base64.stringify(str)
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
    }

    render() {
        const {
            children,
            authority,
            clientId,
            clientSecret,
            redirectUri,
            disablePKCE,
            responseMode,
            responseType,
            scope
        } = this.props;

        const userManager = {
            getUser: async () => {
            },
            signinRedirect: async () => {
                const tokenExpiresAt = JSON.parse(sessionStorage.getItem("oidcInfo"))?.expires_at;
                const now = moment().unix();

                if (!tokenExpiresAt || tokenExpiresAt - now <= 0) {
                    await axios.get(`${process.env.REACT_APP_ACM_AUTHORITY}/.well-known/openid-configuration`)
                        .then(res => {
                            let stateCode = "", code_verifier, code_challenge, authPath;

                            const authorization_endpoint = res.data.authorization_endpoint;
                            const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                            const charactersLength = characters.length;

                            for (let i = 0; i < 29; i++) {
                                stateCode += characters.charAt(Math.floor(Math.random() * charactersLength));
                            }

                            let oidcObject = {
                                authority: authority,
                                client_id: clientId,
                                response_type: responseType,
                                redirect_uri: redirectUri,
                                id: stateCode,
                                response_mode: responseMode,
                                scope: scope
                            };

                            if (clientSecret) {
                                oidcObject.client_secret = clientSecret;
                            }

                            if (!disablePKCE) {
                                code_verifier = this.base64URLEncode(CryptoJS.lib.WordArray.random(50));
                                code_challenge = this.base64URLEncode(CryptoJS.SHA256(code_verifier));
                                oidcObject.code_verifier = code_verifier;
                            }

                            authPath = `${authorization_endpoint}?client_id=${oidcObject.client_id}&redirect_uri=${oidcObject.redirect_uri}&response_type=${oidcObject.response_type}&scope=${oidcObject.scope.replace(" ", "+")}&state=${oidcObject.id}`;
                            !disablePKCE && (authPath += `&code_challenge=${code_challenge}&code_challenge_method=S256`);
                            responseMode && (authPath += `&response_mode=${responseMode}`);

                            localStorage.setItem(`oidc.${stateCode}`, JSON.stringify(oidcObject));
                            window.location = authPath;
                        })
                        .catch(err => {
                            console.error(err)
                        });
                }
            },
            signinCallback: () => {
            },
            events: {
                addUserLoaded: async () => {
                    const tokenExpiresAt = JSON.parse(sessionStorage.getItem("oidcInfo"))?.expires_at;
                    const now = moment().unix();

                    if (((tokenExpiresAt && tokenExpiresAt - now <= 0) || !tokenExpiresAt) && history.location.search) {
                        let openidConfig;
                        const state = history.location.search.split("state=")[1];
                        let code = history.location.search.split("code=")[1];
                        const oidcObject = JSON.parse(localStorage.getItem(`oidc.${state}`));
                        code = code.split("&state=")[0];

                        if (oidcObject.id === state) {
                            await axios.get(`${process.env.REACT_APP_ACM_AUTHORITY}/.well-known/openid-configuration`)
                                .then(async res => {
                                    openidConfig = res.data;

                                    let data = {
                                        client_id: oidcObject.client_id,
                                        code: code,
                                        code_verifier: oidcObject.code_verifier,
                                        grant_type: "authorization_code",
                                        redirect_uri: process.env.REACT_APP_ACM_LOGIN_REDIRECT_URL
                                    };

                                    if (oidcObject.client_secret) {
                                        data.client_secret = oidcObject.client_secret;
                                    }
                                    data = qs.stringify(data);

                                    await axios.post(openidConfig.token_endpoint, data, {headers: {"Content-Type": "application/x-www-form-urlencoded"}})
                                        .then(async res => {
                                            let data = cloneDeep(res.data);
                                            data.expires_at = moment().add(data.expires_in, 'seconds').format('X');
                                            data.expires_at = parseInt(data.expires_at);
                                            sessionStorage.setItem("oidcInfo", JSON.stringify(data));
                                            sessionStorage.setItem("authorizationToken", res.data.id_token);
                                            localStorage.removeItem(`oidc.${state}`)
                                            await this.props.setAuth();
                                        })
                                        .catch(err => {
                                            console.error(err)
                                        });

                                })
                                .catch(err => {
                                    console.error(err)
                                });
                        }
                    }
                }
            }
        };

        return (
            <AuthProvider
                userManager={userManager}
            >
                {children}
            </AuthProvider>
        );
    }
}

export default connect(null, {setAuth})(AuthComponent);