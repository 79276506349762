import axios from "axios";
import {cloneDeep, isEmpty} from "lodash";
import {handleErrors} from "./commonActions";
import history from "../../history";

export const ORG_FETCH_DATA = 'ORG_FETCH_DATA';
export const ORG_PENDING = 'ORG_PENDING';
export const ORG_DONE = 'ORG_DONE';
export const ORG_ERROR = 'ORG_ERROR';
export const ORG_ERROR_CLEAR = 'ORG_ERROR_CLEAR';
export const ORG_CLEAR_STATE_DATA = 'ORG_CLEAR_STATE_DATA';
export const ORG_INFO_TOGGLE_MODAL = 'ORG_INFO_TOGGLE_MODAL';
export const ORG_INFO_FETCH_DATA = 'ORG_INFO_FETCH_DATA';
export const ORG_INFO_PENDING = 'ORG_INFO_PENDING';
export const ORG_INFO_DONE = 'ORG_INFO_DONE';
export const ORG_INFO_ERROR = 'ORG_INFO_ERROR';
export const ORG_INFO_CLEAR_STATE_DATA = 'ORG_INFO_CLEAR_STATE_DATA';
export const ORG_SELECT_SPECIAL_ORG = 'ORG_SELECT_SPECIAL_ORG';

let apiURL = process.env.REACT_APP_API_URL, apiUrlES = process.env.REACT_APP_API_ES_URL;

//initial values for the pagination
const initialPagination = {
    currentPage: 1,
    currentRowsPerPage: 10
};

//initial values for filtering table results
const initialFilters = {
    searchString: "",
    orgIds: [],
};

const infoInitialFilters = {
    sort: {
        order: "DESC",
        orderBy: "privilege"
    }
};

/**
 * fetch all the organizations data
 *
 * @param {object} filters receive an object with the filters configurations
 * @param {string} type flag if want self or all organizations
 */
export const fetchOrganizations = (type, filters = initialFilters) => async dispatch => {
    dispatch({type: ORG_PENDING});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        let orgURL;
        type === "self" ? orgURL = "/organization/self" : orgURL = "/organization/search";

        const query = {
            queryParameters: {
                id: filters.searchString ? filters.orgIds : [],
            },
            returnValues: ["id", "name"]
        };
        const response = await axios.post(apiURL + orgURL, query, {headers: {Authorization: authorizationToken}});
        if (response) {
            response.data.response.some(org => {
                if (org.organization_code === process.env.REACT_APP_SPECIAL_ORG) {
                    localStorage.setItem("specialOrgValue", JSON.stringify(org));
                    return true;
                }
                return false;
            })
            dispatch({type: ORG_FETCH_DATA, payload: {organizations: response.data.response}});
        }
        dispatch({type: ORG_DONE});
    } catch (err) {
        dispatch(handleErrors(err, ORG_ERROR));
    }
};

/**
 * fetch all the filtered organizations data
 *
 * @param {object} filters receive an object with the filters configurations
 */
export const filterFetchOrganizations = (filters = initialFilters) => async dispatch => {
    dispatch({type: ORG_PENDING});
    try {
        let filtersCopy = filters, orgIds = [];
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgResponse = await axios.post(apiURL + '/organization/self', {
            queryParameters: {},
            returnValues: ["id"]
        }, {headers: {Authorization: authorizationToken}});

        await Promise.all(orgResponse.data.response.map(org => orgIds.push(org.id)));

        const orgESResponse = await axios.post(apiUrlES + '/essearch/organisation', {
            searchstring: filters.searchString,
            attributes: ["name", "organization_code"],
            organization_id: orgIds
        }, {headers: {"Authorization": authorizationToken}});

        if (!isEmpty(orgESResponse?.data)) {
            orgIds = [];
            await Promise.all(orgESResponse.data.map(org => orgIds.push(parseInt(org._source.id))));
            filtersCopy.orgIds = orgIds;
            await dispatch(fetchOrganizations("self", filtersCopy));
        } else {
            dispatch({type: ORG_FETCH_DATA, payload: []});
            dispatch({type: ORG_DONE});
        }
    } catch (err) {
        dispatch(handleErrors(err, ORG_ERROR));
    }
};

/**
 * retrieve all the organizations which user typed in autocomplete
 *
 * @param {string} value the organization name which will be searched
 */
export const searchOrganization = value => async dispatch => {
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const response = await axios.post(apiUrlES + '/essearch/organisation', {
            searchstring: value,
            attributes: ["name", "organization_code"],
            organization_id: []
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.data;
        }
    } catch (err) {
        dispatch(handleErrors(err, ORG_ERROR));
    }
};

/**
 * retrieve all users info/roles from a specific organization
 *
 * @param {object} pagination receive an object with the pagination configurations
 * @param {number} orgId the organization id to get retrieve the information
 * @param {object} filters receive an object with the filters configurations
 */
export const fetchOrganizationInfo = (pagination = initialPagination, orgId, filters = infoInitialFilters) => async dispatch => {
    dispatch({type: ORG_INFO_PENDING});
    try {
        let filtersCopy = cloneDeep(filters);
        if (filtersCopy.sort.orderBy.includes("parsed_")) {
            filtersCopy.sort.orderBy = filtersCopy.sort.orderBy.split("parsed_")[1];
        }
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const pageIndex = (pagination.currentPage * pagination.currentRowsPerPage) - (pagination.currentRowsPerPage - 1);

        const query = {
            queryParameters: {
                organization_id: orgId,
                index: pageIndex,
                limit: pagination.currentRowsPerPage,
                order: filtersCopy.sort.order,
                orderBy: filtersCopy.sort.orderBy
            }
        };
        const response = await axios.post(apiURL + '/organization/info', query, {headers: {Authorization: authorizationToken}});

        dispatch({type: ORG_INFO_FETCH_DATA, payload: response.data});

        dispatch({type: ORG_INFO_DONE});
    } catch (err) {
        dispatch(handleErrors(err, ORG_INFO_ERROR));
    }
};

/**
 * toggle the organization modal info
 *
 * @param {boolean} value boolean value to open/close modal
 * @param {object} org the organization to get retrieve the information
 */
export const toggleOrgInfoModal = (value, org) => async dispatch => {
    dispatch({type: ORG_INFO_TOGGLE_MODAL, payload: {toggleModal: value, organization: org}});
};

/**
 * toggle the organization modal info
 *
 * @param {boolean} values values necessary to update the redirected email
 */
export const updateOrganizationInfoModal = values => async dispatch => {
    dispatch({type: ORG_ERROR_CLEAR});
    try {
        const authorizationToken = sessionStorage.getItem('authorizationToken');
        const orgId = parseInt(localStorage.getItem('organizationId'));
        const response = await axios.post(apiURL + '/organization/update', {
            organization_id: orgId,
            data: {
                id: values.id,
                is_redirected_email: values.is_redirected_email,
                redirected_email: values.redirected_email || ""
            }
        }, {headers: {Authorization: authorizationToken}});
        if (response) {
            return response.status;
        }
    } catch (err) {
        dispatch(handleErrors(err, ORG_ERROR));
    }
};

/**
 * clears all the state data
 */
export const clearStateData = () => async dispatch => {
    dispatch({type: ORG_CLEAR_STATE_DATA});
};

/**
 * clears only the organization information state data
 */
export const clearInfoStateData = () => async dispatch => {
    dispatch({type: ORG_INFO_CLEAR_STATE_DATA});
};

/**
 * flag org page to choose the special org
 *
 * @param {string} location the location where user want to go
 */
export const selectSpecialOrg = location => async dispatch => {
    history.push('/organisatie');
    dispatch({type: ORG_SELECT_SPECIAL_ORG, payload: location});
};